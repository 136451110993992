/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import CustomersDashboard from './Pages/Customer/Header'
import Dashboard from './Pages/Dashboard'
import LoginPage from './Pages/Login'
import PrivateRoute from './Pages/Login/PrivateRoute'
import DeleteAccount from './Pages/PublicPage/DeleteAccount'
import PageNotFound from './Pages/PublicPage/PageNotFound'
import { persistor, store } from './redux/store'

function App() {
  useEffect(() => {
    const url = window.location.href

    if (url.endsWith('#')) {
      window.location.href = window.location.origin + window.location.pathname
    }
  }, [window])

  const router = createBrowserRouter([
    {
      path: '/',
      element: localStorage.getItem('access_token') ? <Navigate to={'/dashboard'} /> : <Navigate to={'/login'} />
    },
    {
      path: '/delete_account',
      element: <DeleteAccount />
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: '/dashboard/',
          element: <Dashboard />
        },
        {
          path: '/dashboard/:id',
          element: <CustomersDashboard />
        },
        {
          path: '/dashboard/:id/:email',
          element: <CustomersDashboard />
        }
      ]
    },
    {
      path: '*',
      element: <PageNotFound />
    }
  ])

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </>
  )
}

export default App
