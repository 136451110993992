import React from 'react'

interface ChartSVGProps {
  fillColor: string // Add a type annotation for the fillColor prop
  // width?: string
  height?: string
}

const ChartSVG: React.FC<ChartSVGProps> = ({
  fillColor,
  //  width,
  height
}) => {
  return (
    <svg height={height} width='7'>
      <defs id='defs2989'></defs>
      <g id='layer1' transform='translate(0,-1036.3622)'>
        <rect height={height} id='rect2997' fill={fillColor} width='6' x='1' y='1037.3622'></rect>
      </g>
    </svg>
  )
}

export default ChartSVG
