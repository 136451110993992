import TablePagination from '@mui/material/TablePagination'
import { CloseCircle, TickCircle } from 'iconsax-react'
import moment from 'moment'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  useGeneralizedSupervision,
  useSocialSupervision,
  useWebMonitoring
} from '../../../../hook/parental-control/useParental'
import { currentISOTime, getColorCode, startOfMonth, startOfWeek } from '../../../../utils/helper'
import WebSuperVisionTile from '../../web-supervision/web-supervision-tile'
import SocialPieChart from '../../charts/social-supervision-pie-chart'
import './styles.scss'
import LoadingSpinner from '../../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import SessionTimeOutModal from '../../../SessionTimeOutModal'
import { useSessionContex } from '../../../../SessionContex'

interface Props {
  email: string
  dataFilter: 'today' | 'thisWeek' | 'thisMonth'
  deviceFilter: any
  showSocialChart: boolean
  fromDate?: any
}

const SupervisionTableCommon: FC<Props> = ({ email, dataFilter, deviceFilter, showSocialChart, fromDate }) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  // pagination
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [row, setRow] = useState<any>([])
  const [filterData, setFilterData] = useState<any>([])
  const [webSelected, setWebSelected] = useState<string>('')

  // const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { data, isLoading, refetch, isFetching, error } = useWebMonitoring(
    fromDate || currentISOTime(),
    email,
    deviceFilter
  )
  // const { data: socialSupervision, refetch: socialSupervisionRefetch }: any = useSocialSupervision(
  //   fromDate ? fromDate : currentISOTime(),
  //   email,
  //   deviceFilter
  // )
  useEffect(() => {
    if (data) {
      if (data === 401) {
        !openModal && setOpenModal(true)
      } else {
        setRow(data?.results?.activities)
      }
    } else {
      setRow([])
    }
    !isFetching &&
      setTimeout(() => {
        setLoading(false)
      }, 1000)
  }, [data, isFetching])

  useEffect(() => {
    if (row) {
      setFilterData(webSelected === '' ? row : row?.filter((obj: any) => obj.category_name === webSelected))
    }
  }, [row])

  useEffect(() => {
    setLoading(true)
    setWebSelected('')
    setCurrentPage(0)
    setOffset(0)
    refetch()
    // socialSupervisionRefetch()
  }, [deviceFilter, dataFilter, fromDate])

  useEffect(() => {
    if (reCall) {
      setLoading(true)
      setWebSelected('')
      if (currentPage === 0 || offset === 0) {
        refetch()
      } else {
        setCurrentPage(0)
        setOffset(0)
      }
      // socialSupervisionRefetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    // setRowsPerPage(+event.target.value)
    setLimit(parseInt(event.target.value))
    setOffset(0)
    setCurrentPage(0)
  }
  const onWebClick = (selected: any) => {
    setWebSelected(webSelected !== selected.title ? selected.title : '')
    setCurrentPage(0)
    setOffset(0)
    setFilterData(
      webSelected !== selected.title ? row?.filter((obj: any) => obj.category_name === selected.title) : row
    )
  }

  const sum = data?.results?.web_chart_items?.reduce((accumulator: any, item: any) => accumulator + item.count, 0) || 1

  return (
    <div className='bg-white table-main-wrapper'>
      <div className='overflow-x-auto pb-3 mb-[16px] px-6 py-4 table-inner-wrapper'>
        {/* {!isFetching &&
          data &&
          data.results?.web_chart_items
            ?.sort((a: any, b: any) => b.count - a.count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.count}
                  percentage={Math.ceil((item.count / sum) * 100)}
                  title={item.title}
                  onClick={() => onWebClick(item)}
                  active={webSelected}
                />
              )
            })} */}
        {/* {!isFetching && (data?.results?.web_chart_items.length === 0 || error !== null) ? (
          <Box color={getColorCode('redToBlue')} margin={'auto'}>
            Web chart count not found
          </Box>
        ) : (
          data &&
          data.results?.web_chart_items
            ?.sort((a: any, b: any) => b.count - a.count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.count}
                  percentage={Math.ceil((item.count / sum) * 100)}
                  title={item.title}
                  onClick={() => onWebClick(item)}
                  active={webSelected}
                />
              )
            })
        )} */}
        {isFetching ? (
          <>
            {Array.from({ length: 8 }, (_, index) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_loading_tile_${index}`}
                  value={'-' as unknown as number}
                  percentage={100}
                  title={'-'}
                  animate={true}
                />
              )
            })}
          </>
        ) : data && data?.results?.web_chart_items?.length !== 0 ? (
          data.results?.web_chart_items
            ?.sort((a: any, b: any) => b.count - a.count)
            .map((item: any, index: number) => {
              return (
                <WebSuperVisionTile
                  key={`supervision_tile_${index}`}
                  value={item.count}
                  percentage={Math.ceil((item.count / sum) * 100)}
                  title={item.title}
                  onClick={() => onWebClick(item)}
                  active={webSelected}
                />
              )
            })
        ) : (
          <Box color={getColorCode('redToBlue')} margin={'auto'}>
            Web chart count not found
          </Box>
        )}
      </div>

      {showSocialChart && (
        <SocialPieChart email={email} deviceFilter={deviceFilter} dataFilter={dataFilter} fromDate={fromDate} />
      )}

      <table className='pc-table'>
        <thead>
          <tr>
            <td>Allowed</td>
            <td>Visit Counts</td>
            <td>Category</td>
            <td>Website</td>
            <td>Device Name</td>
            <td>Time</td>
          </tr>
        </thead>
        <tbody>
          {(isFetching || loading) && (
            <tr>
              <td colSpan={6} style={{ width: '100%' }}>
                <Box display={'flex'} justifyContent={'center'}>
                  <LoadingSpinner />
                </Box>
                {/* <span className='animate-pulse mx-auto'>Loading</span> */}
              </td>
            </tr>
          )}

          {!isFetching &&
            !loading &&
            filterData &&
            filterData?.length !== 0 &&
            filterData
              ?.sort((a: any, b: any) => {
                if (a.readable_start_datetime > b.readable_start_datetime) return -1
                if (a.readable_start_datetime < b.readable_start_datetime) return 1
                return 0
              })
              ?.slice(offset, offset + limit)
              .map((row: any) => {
                const time = moment
                  .utc(row.readable_start_datetime)
                  .local()
                  .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                // moment(row.readable_start_datetime).format('D.M.YYYY hh:mm A').split(' ')

                return (
                  <tr>
                    <td>
                      {row.title.includes('blocked website') || row.title.includes('Failed') ? (
                        <CloseCircle size='32' color={getColorCode('redToYellow')} variant='Bold' />
                      ) : (
                        <TickCircle size='32' color={getColorCode('greenToGreen')} variant='Bold' />
                      )}
                    </td>
                    <td>{row.visit_count || 0}</td>
                    <td>{row.category_name || '-'}</td>
                    <td>{row.hostname || '-'}</td>
                    <td>{row.device_name || 'Unknown'}</td>
                    <td>
                      {time}
                      {/* {time[0]}
                      <br />
                      {time[1] + ' ' + time[2]} */}
                    </td>
                  </tr>
                )
              })}
          {!isFetching && !loading && filterData?.length === 0 && (
            <tr>
              <td colSpan={6} align='center'>
                <Box display={'flex'} justifyContent={'center'} textAlign={'center'} color={getColorCode('redToBlue')}>
                  No Data found
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!isFetching && filterData && (
        <TablePagination
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[3, 6, 10, 20, 50]}
          component='div'
          count={filterData?.length || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default SupervisionTableCommon
