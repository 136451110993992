export const customerSystemOverviewData = [
  {
    icon: 'internet',
    title: 'Internet Connection',
    description: 'WiFi Network is Online',
    status: 'online',
    value: 'online',
    target: 'online',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'success'
  },
  {
    icon: 'environment',
    title: 'Environment',
    description: 'High Congestion (+3)',
    status: 'critical',
    value: '+3',
    target: '0',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'critical-last'
  },
  {
    icon: 'stability',
    title: 'Stability',
    description: 'Health Optimization',
    status: 'warning',
    value: '70%',
    target: '100%',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'warning-last'
  },
  {
    icon: 'network',
    title: 'Network Speed',
    description: 'Network speed is Normal',
    status: 'online',
    value: '988 Mbps',
    target: '1Gbps',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'success'
  },
  {
    icon: 'coverage1',
    title: 'WiFi Coverage',
    description: 'WiFi Coverage is normal',
    status: 'online',
    value: '48',
    target: '<50',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'success'
  },
  // {
  //   icon: 'coverage2',
  //   title: 'WiFi Coverage',
  //   description: 'WiFi Coverage is normal',
  //   status: 'online',
  //   value: '48',
  //   target: '<50',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'success'
  // },
  {
    icon: 'telemetry',
    title: 'Telemetry Data',
    description: 'Access to Telemetry Data is normal',
    status: 'online',
    value: 'YES',
    target: 'YES',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'success'
  },
  {
    icon: 'problem',
    title: 'Problems',
    description: 'System is operating below expectations',
    status: 'critical',
    value: '+3',
    target: '0',
    trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
    indicator: 'critical'
  }
  // {
  //   icon: 'coverage2',
  //   title: 'Trend Status',
  //   description: 'Trend Status E9',
  //   status: 'online',
  //   value: 'online',
  //   target: 'online',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'success'
  // },
  // {
  //   icon: 'problem',
  //   title: 'Current Value',
  //   description: 'Current Value E10',
  //   status: 'critical',
  //   value: '+3',
  //   target: '0',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'critical'
  // },
  // {
  //   icon: 'network',
  //   title: 'Target Value',
  //   description: 'Target Value E11',
  //   status: 'warning',
  //   value: '70%',
  //   target: '100%',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'warning'
  // },
  // {
  //   icon: 'environment',
  //   title: 'Trend Line',
  //   description: 'Trend Line E12',
  //   status: 'online',
  //   value: '988 Mbps',
  //   target: '1Gbps',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'success'
  // },
  // {
  //   icon: 'internet',
  //   title: 'Indicator',
  //   description: 'Indicator E13',
  //   status: 'online',
  //   value: '48',
  //   target: '<50',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'success'
  // },
  // {
  //   icon: 'stability',
  //   title: 'Page',
  //   description: 'Page E14',
  //   status: 'online',
  //   value: '48',
  //   target: '<50',
  //   trendline: [30, 70, 135, 50, 70, 91, 25, 100, 80, 65],
  //   indicator: 'success'
  // }
]
