import { FC, useEffect, useState } from 'react'
// import moment from 'moment'
import { useGatewayStats } from '../../../hook/useGatewayStats'
import GatewayStatisticsLineChart from './gateway-statistics/gateway-statistics-line-chart'
import GatewayLoadStatisticsLineChart from './gateway-statistics/gateway-average-load-statistics-line-chart'
import GatewayFreeMemoryStatisticsLineChart from './gateway-statistics/gateway-free-memory-statistics-line-chart'
import Loader from '../../../components/Loader'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import dayjs from 'dayjs'
import { useSessionContex } from '../../../SessionContex'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const Statistics: FC = () => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const activeGw_Uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data: gwData, refetch: gatewayInfoRef }: any = useGatewayInfo(activeGw_Uid) //User device data

  // const chartfromDate = moment.tz(moment.utc().subtract(FilterData, 'hours'), indiaTimezone).format('YYYYMMDDTHH:mm:ss')

  const [dataFilter, setDataFilter] = useState<'day' | 'isoWeek' | 'isoMonth' | 'isoYear'>('day')
  // const [wifi0, setWiFi0] = useState<any>([])
  // const [wifi1, setWiFi1] = useState<any>([])
  const [hours, setHours] = useState<number>(24)
  const [loader, setLoader] = useState<any>(true)
  // dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(hours, 'hours')).format('YYYYMMDDTHH:mm:ss.SSSSSS')
  const chartfromDate = gwData?.is_online
    ? // ? dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(hours, 'hours')).format('YYYYMMDDTHH:mm:ss.SSSSSS')
      dayjs.utc().subtract(hours, 'hours').format('YYYYMMDDTHH:mm:ss.SSSSSS')
    : dayjs.utc(gwData?.last_seen).subtract(hours, 'hours').format('YYYYMMDDTHH:mm:ss.SSSSSS')
  // : dayjs
  //     .utc(dayjs.tz(dayjs(gwData?.last_seen), timeZone).subtract(hours, 'hours'))
  //     .format('YYYYMMDDTHH:mm:ss.SSSSSS')
  const charttillDate = gwData?.is_online
    ? // ? dayjs.utc(dayjs.tz(dayjs(), timeZone)).format('YYYYMMDDTHH:mm:ss.SSSSSS')
      dayjs.utc().format(`YYYYMMDDTHH:mm:ss.000000`)
    : dayjs.utc(gwData?.last_seen).format(`YYYYMMDDTHH:mm:ss.000000`)
  // : dayjs.utc(dayjs.tz(dayjs(gwData?.last_seen), timeZone)).format('YYYYMMDDTHH:mm:ss.SSSSSS')
  const [dateArray, setDateArray] = useState<any>([])
  const [shortArray, setShortArray] = useState<any>([])
  const [interval, setInterval] = useState<any>(1 * 3600 * 1000)

  const { data, isLoading, isFetching, refetch } = useGatewayStats(chartfromDate, charttillDate, activeGw_Uid)

  const updateDateArray = () => {
    // Get the current date
    const currentDate = gwData?.is_online
      ? dayjs.tz(dayjs.utc(), timeZone).format('YYYYMMDDTHH:mm:ss')
      : dayjs.tz(dayjs.utc(gwData?.last_seen), timeZone).format('YYYYMMDDTHH:mm:ss')
    if (dataFilter === 'day') {
      const dateArray = []
      const startOfDay = gwData?.is_online
        ? dayjs.tz(dayjs.utc().subtract(24, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')
        : dayjs.tz(dayjs.utc(gwData?.last_seen).subtract(24, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')
      let currentDay = dayjs(currentDate)
      const endDate = dayjs(startOfDay)
      while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
        dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss') })
        currentDay = currentDay.subtract(1, 'hour')
      }

      setDateArray(dateArray)
      setInterval(1 * 3600 * 1000)
    }
    if (dataFilter === 'isoWeek') {
      // Get the date from 3 months ago
      const weekAgo = gwData?.is_online
        ? dayjs.tz(dayjs.utc().subtract(168, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')
        : dayjs.tz(dayjs.utc(gwData?.last_seen).subtract(168, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')

      // Create an array of dates between currentDate and threeMonthsAgo
      const dateArray = []
      let currentDay = dayjs(currentDate)
      const endDate = dayjs(weekAgo)

      while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
        dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss') })
        currentDay = currentDay.subtract(1, 'day')
      }
      setDateArray(dateArray)
      setInterval(24 * 3600 * 1000)
    }
    if (dataFilter === 'isoMonth') {
      // Get the date from 3 months ago
      const monthsAgo = gwData?.is_online
        ? dayjs.tz(dayjs.utc().subtract(1, 'months'), timeZone).format('YYYYMMDDTHH:mm:ss')
        : dayjs.tz(dayjs.utc(gwData?.last_seen).subtract(1, 'months'), timeZone).format('YYYYMMDDTHH:mm:ss')

      // Create an array of dates between currentDate and threeMonthsAgo
      const dateArray = []
      let currentDay = dayjs(currentDate)
      const endDate = dayjs(monthsAgo)

      while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
        dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss') })
        currentDay = currentDay.subtract(1, 'day')
      }
      setDateArray(dateArray)
      setInterval(48 * 3600 * 1000)
    }
    if (dataFilter === 'isoYear') {
      // Get the date from 3 months ago
      const threeMonthsAgo = gwData?.is_online
        ? dayjs.tz(dayjs.utc().subtract(3, 'months'), timeZone).format('YYYYMMDDTHH:mm:ss')
        : dayjs.tz(dayjs.utc(gwData?.last_seen).subtract(3, 'months'), timeZone).format('YYYYMMDDTHH:mm:ss')

      // Create an array of dates between currentDate and threeMonthsAgo
      const dateArray = []
      let currentDay = dayjs(currentDate)
      const endDate = dayjs(threeMonthsAgo)

      while (currentDay.isAfter(endDate) || currentDay.isSame(endDate)) {
        dateArray?.push({ timestamp: currentDay.format('YYYYMMDDTHH:mm:ss') })
        currentDay = currentDay.subtract(1, 'day')
      }

      setDateArray(dateArray)
      setInterval(168 * 3600 * 1000)
    }
  }

  useEffect(() => {
    // refetch()
    gatewayInfoRef()
    setLoader(true)
  }, [activeGw_Uid, hours])

  useEffect(() => {
    if (gwData) {
      refetch()
      updateDateArray()
    }
  }, [gwData])

  useEffect(() => {
    if (reCall) {
      setLoader(true)
      updateDateArray()
      refetch()
      gatewayInfoRef()
    }
  }, [reCall])

  useEffect(() => {
    if (!(loader || isLoading || isFetching)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [loader, isLoading, isFetching])

  const convertTimestampFormat = (timestamp: any) => {
    const formattedTimestamp = dayjs.utc(timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()
    return formattedTimestamp
  }

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
      setLoader(false)
    } else {
      // if (dataFilter !== 'day') {
      //   const newArray: any = dateArray?.map((item: any) => {
      //     let obj: any = { timestamp: dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'), y: 0 }
      //     const filterArray = data?.filter(
      //       (obj: any) =>
      //         dayjs.utc(obj.timestamp).format('YYYY-MM-DD ') === dayjs(item.timestamp).format('YYYY-MM-DD')
      //     )

      //     const timeDate = convertTimestampFormat(dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))
      //     if (filterArray && filterArray.length !== 0) {
      //       const avgY = filterArray.reduce((sum: any, obj: any) => sum + obj.load_average, 0) / filterArray.length
      //       const wifi0Y = filterArray.reduce((sum: any, obj: any) => sum + obj.wifi_temp0, 0) / filterArray.length
      //       const wifi1Y = filterArray.reduce((sum: any, obj: any) => sum + obj.wifi_temp1, 0) / filterArray.length
      //       const memfreeY = filterArray.reduce((sum: any, obj: any) => sum + obj.memfree, 0) / filterArray.length
      //       obj = {
      //         timestamp: timeDate,
      //         x: timeDate,
      //         load_average: avgY,
      //         wifi_temp0: wifi0Y,
      //         wifi_temp1: wifi1Y,
      //         memfree: memfreeY
      //       }
      //     } else {
      //       obj = {
      //         timestamp: timeDate,
      //         x: timeDate,
      //         load_average: 0,
      //         wifi_temp0: 0,
      //         wifi_temp1: 0,
      //         memfree: 0
      //       }
      //     }
      //     return obj
      //   })
      //   // const sortedTimeArray: any =
      //   //   newArray &&
      //   //   newArray?.sort((a: any, b: any) => {
      //   //     let dateA: any = new Date(a.timestamp)
      //   //     let dateB: any = new Date(b.timestamp)
      //   //     return dateA - dateB
      //   //   })
      //   setShortArray(newArray)
      // } else {

      if (typeof data === 'object') {
        const newArray: any = dateArray?.map((item: any, key: any) => {
          let obj: any = { timestamp: dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'), y: 0 }
          const filterArray =
            data &&
            data.length !== 0 &&
            data?.filter((obj: any) => {
              return (
                dayjs.tz(dayjs.utc(obj.timestamp), timeZone).format('YYYY-MM-DD HH') ===
                // dayjs(obj.timestamp).format(`YYYY-MM-DD HH`) ===
                dayjs(item.timestamp).format('YYYY-MM-DD HH')
              )
            })
          // const timeDate = convertTimestampFormat(dayjs(item.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))

          const timeDate: any = dayjs.utc(item.timestamp, 'YYYYMMDDTHH:mm:ss').toDate()

          if (filterArray && filterArray.length !== 0) {
            const avgY = filterArray.reduce((sum: any, obj: any) => sum + obj.load_average, 0) / filterArray.length
            const wifi0Y = filterArray.reduce((sum: any, obj: any) => sum + obj.wifi_temp0, 0) / filterArray.length
            const wifi1Y = filterArray.reduce((sum: any, obj: any) => sum + obj.wifi_temp1, 0) / filterArray.length
            const memfreeY = filterArray.reduce((sum: any, obj: any) => sum + obj.memfree, 0) / filterArray.length

            obj = {
              timestamp: timeDate,
              x: timeDate,
              load_average: avgY,
              wifi_temp0: wifi0Y,
              wifi_temp1: wifi1Y,
              memfree: memfreeY
            }
          } else {
            obj = {
              timestamp: timeDate,
              x: timeDate,
              load_average: 0,
              wifi_temp0: 0,
              wifi_temp1: 0,
              memfree: 0
            }
          }

          return obj
        })

        const sortedTimeArray: any =
          newArray &&
          newArray?.sort((a: any, b: any) => {
            let dateA: any = new Date(a.timestamp)
            let dateB: any = new Date(b.timestamp)
            return dateA - dateB
          })

        setShortArray(sortedTimeArray)
      }

      // //======================================
      // const chunkSize = Math.ceil(data?.length / 100)
      // const aggregatedloadAverage = []
      // // Loop through the original data and calculate the average for each chunk
      // for (let j = 0; j < data?.length; j += chunkSize) {
      //   const chunk = data?.slice(j, j + chunkSize)
      //   // Calculate the average temperature for the chunk
      //   const totalTemperature = chunk.reduce((sum: any, dataPoint: any) => sum + dataPoint.load_average, 0)
      //   const averageTemperature = totalTemperature / chunk.length
      //   const totalTemperature1 = chunk.reduce((sum: any, dataPoint: any) => sum + dataPoint.wifi_temp0, 0)
      //   const averageTemperature1 = totalTemperature1 / chunk.length
      //   const totalTemperature2 = chunk.reduce((sum: any, dataPoint: any) => sum + dataPoint.wifi_temp1, 0)
      //   const averageTemperature2 = totalTemperature2 / chunk.length
      //   const totalTemperature3 = chunk.reduce((sum: any, dataPoint: any) => sum + dataPoint.memfree, 0)
      //   const averageTemperature3 = totalTemperature3 / chunk.length
      //   // const timestamp = chunk[0].timestamp
      //   const timestamp = convertTimestampFormat(chunk[0].timestamp)

      //   aggregatedloadAverage.push({
      //     timestamp: timestamp,
      //     x: timestamp,
      //     load_average: averageTemperature,
      //     wifi_temp0: averageTemperature1,
      //     wifi_temp1: averageTemperature2,
      //     memfree: averageTemperature3
      //   })
      //   // console.log('=========@@', aggregatedloadAverage)

      //   // setShortArray(aggregatedloadAverage)
      // }
      // }

      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [data])

  const wifi0 =
    (data &&
      shortArray &&
      shortArray?.length > 0 &&
      shortArray?.map((item: any) => {
        return { y: item.wifi_temp0, timestamp: item.timestamp, x: item.x }
      })) ||
    []

  const wifi1 =
    (data &&
      shortArray &&
      shortArray?.length > 0 &&
      shortArray?.map((item: any) => {
        return { y: item.wifi_temp1, timestamp: item.timestamp, x: item.x }
      })) ||
    []

  const loadAverage =
    (data &&
      shortArray &&
      shortArray?.length > 0 &&
      shortArray?.map((item: any) => {
        return {
          y: item.load_average,
          timestamp: item.timestamp,
          x: item.x
        }
      })) ||
    []

  const freeMemory =
    (data &&
      shortArray &&
      shortArray?.length > 0 &&
      shortArray?.map((item: any) => {
        return { y: item.memfree / 1024, timestamp: item.timestamp, x: item.x }
      })) ||
    []

  const handleDataFilter = (filter: 'day' | 'isoWeek' | 'isoMonth' | 'isoYear', hours: number) => {
    if (!isLoading && !isFetching) {
      setDataFilter(filter)
      setHours(hours)
    }
  }

  return (
    <div className=''>
      <div className='w-full overflow-y-auto'>
        <div>
          {data?.length > 0 && (
            <div className='w-full d-flex py-2 justify-end'>
              {/* <span style={{ padding: '8px 16px' }}> {gwData?.is_online ? '' : 'Last Seen To'}</span> */}
              <span
                onClick={() => handleDataFilter('day', 24)}
                className={
                  dataFilter === 'day'
                    ? `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'hitron-active-filter'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'customer-active-filter'
                          : 'active-filter'
                      } cursor-pointer`
                    : 'inactive-filter cursor-pointer'
                }
                data-testid='testid_stat_last_24_hours'
              >
                Last 24 Hours
              </span>
              {data?.length > 0 && (
                <span
                  onClick={() => handleDataFilter('isoWeek', 168)}
                  className={
                    dataFilter === 'isoWeek'
                      ? `${
                          process.env.REACT_APP_COLOR === 'hitron'
                            ? 'hitron-active-filter'
                            : process.env.REACT_APP_COLOR === 'customer'
                            ? 'customer-active-filter'
                            : 'active-filter'
                        } cursor-pointer`
                      : 'inactive-filter cursor-pointer'
                  }
                  data-testid='testid_stat_last_7_days'
                >
                  Last 7 Days
                </span>
              )}
              {data?.length > 0 && (
                <span
                  onClick={() => handleDataFilter('isoMonth', 730)}
                  className={
                    dataFilter === 'isoMonth'
                      ? `${
                          process.env.REACT_APP_COLOR === 'hitron'
                            ? 'hitron-active-filter'
                            : process.env.REACT_APP_COLOR === 'customer'
                            ? 'customer-active-filter'
                            : 'active-filter'
                        } cursor-pointer`
                      : 'inactive-filter cursor-pointer'
                  }
                  data-testid='testid_stat_last_30_days'
                >
                  Last 30 Days
                </span>
              )}
              {data?.length > 0 && (
                <span
                  onClick={() => handleDataFilter('isoYear', 2190)}
                  className={
                    dataFilter === 'isoYear'
                      ? `${
                          process.env.REACT_APP_COLOR === 'hitron'
                            ? 'hitron-active-filter'
                            : process.env.REACT_APP_COLOR === 'customer'
                            ? 'customer-active-filter'
                            : 'active-filter'
                        } cursor-pointer`
                      : 'inactive-filter cursor-pointer'
                  }
                  data-testid='testid_stat_last_90_days'
                >
                  Last 90 Days
                </span>
              )}
            </div>
          )}
        </div>
        {loader || isLoading || isFetching ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <GatewayStatisticsLineChart wifi0={wifi0} wifi1={wifi1} dataFilter={dataFilter} interval={interval} />
            <GatewayLoadStatisticsLineChart loadAverage={loadAverage} dataFilter={dataFilter} interval={interval} />
            <GatewayFreeMemoryStatisticsLineChart freeMemory={freeMemory} dataFilter={dataFilter} interval={interval} />
          </>
        )}
      </div>
    </div>
  )
}
export default Statistics
