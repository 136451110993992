import React from 'react'

interface HitronLogoProps {
  className?: string
  height?: string
  width?: string
}

const HitronLogo: React.FC<HitronLogoProps> = ({ className, width, height }) => {
  return (
    <div className={className}>
      <svg
        width={width ? width : '227'}
        height={height ? height : '50'}
        viewBox='0 0 227 50'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M54.9115 3.17953C50.6862 0.0188016 44.5294 0.39293 38.5938 3.55628L39.1066 4.43115C42.3062 3.74209 45.286 4.10851 47.6371 5.71313C54.1629 10.1664 53.4103 22.447 45.9601 33.1361C43.8994 36.0913 41.5456 38.6144 39.076 40.6289L39.5768 41.3996C44.3536 39.6987 49.2396 36.1138 53.1239 31.0307C60.9617 20.7764 61.7609 8.3113 54.9115 3.17953Z'
          fill='#009BE5'
        />
        <path
          d='M29.9302 46.8207C34.1635 49.9815 40.3176 49.606 46.2506 46.4439L45.7364 45.5691C42.5421 46.2582 39.557 45.8933 37.2059 44.2871C30.6788 39.8312 31.4314 27.5533 38.8856 16.8655C40.945 13.905 43.2974 11.3846 45.767 9.37271L45.2622 8.60327C40.4907 10.3029 35.6048 13.8852 31.7205 18.9696C23.8813 29.2265 23.0807 41.6916 29.9302 46.8207Z'
          fill='#009BE5'
        />
        <path
          d='M61.3494 17.9876L60.9551 19.2999C62.3711 20.1431 63.1663 21.0719 63.1663 22.0561C63.1663 25.7413 52.0236 28.7334 38.2794 28.7334C24.5312 28.7334 13.3886 25.7413 13.3886 22.0561C13.3886 19.2314 19.921 16.819 29.1415 15.8414L31.1222 13.9968C14.2157 14.4764 0.999054 18.678 0.999054 23.7861C0.999054 29.2156 15.9434 33.6214 34.3765 33.6214C52.8148 33.6214 67.7606 29.2156 67.7606 23.7861C67.7606 21.6188 65.3762 19.616 61.3494 17.9876Z'
          fill='#AACD06'
        />
        <path
          d='M113.639 1.92236C111.019 1.92236 109.158 4.14242 109.158 6.29922C109.158 8.74453 111.019 11.0739 113.758 11.0739C116.604 11.0739 118.408 8.74453 118.408 6.29922C118.408 4.08315 116.314 1.92236 113.639 1.92236Z'
          fill='#005BAC'
        />
        <path
          d='M142.177 38.3364L141.186 35.5301C140.103 36.0663 139.087 36.538 137.346 36.538C134.704 36.538 133.208 34.9701 133.208 29.5986V18.4009H139.461C140.407 18.4009 141.175 17.6381 141.175 16.7052V14.1137H133.208V6.4707H131.029C129.961 6.4707 129.629 7.22829 129.613 7.25992C127.098 11.6802 122.496 14.891 121.036 15.2191V18.4009H125.231V31.8529C125.231 37.9003 128.112 41.3417 134.704 41.3417C137.005 41.3417 139.485 40.8476 141.634 39.8976C142.601 39.4352 142.177 38.3364 142.177 38.3364Z'
          fill='#005BAC'
        />
        <path
          d='M179.925 37.0478C174.736 37.5406 172.781 32.2178 172.343 27.6749C171.924 23.3996 173.07 18.1387 178.039 17.6657C183.118 17.1795 185.201 22.1321 185.614 26.4154C186.06 30.9516 185.225 36.5445 179.925 37.0478ZM193.763 25.6275C192.928 17.0201 185.232 12.7065 177.627 13.4325C170.083 14.1519 163.355 19.833 164.196 28.4444C165 36.6842 171.553 42.1164 180.339 41.2784C189.181 40.4418 194.567 33.866 193.763 25.6275Z'
          fill='#005BAC'
        />
        <path
          d='M160.139 13.4065C157.683 13.4065 155.944 15.7781 154.98 17.0258L153.362 19.3341H153.244V14.114H147.042C146.095 14.114 145.327 14.8716 145.327 15.8097V17.0337V40.6319H151.587C152.534 40.6319 153.302 39.869 153.302 38.9349V29.1233C153.302 22.7162 156.003 20.5224 158.762 20.5224C160.379 20.5224 161.431 20.9493 162.54 21.5304C163.581 18.313 165.538 16.0692 165.538 16.0692C163.86 14.3486 161.94 13.4065 160.139 13.4065Z'
          fill='#005BAC'
        />
        <path
          d='M215.267 13.4065C211.491 13.4065 207.716 14.7648 205.013 17.6134V14.114H198.815C197.868 14.114 197.098 14.8716 197.098 15.8097V40.6319H203.361C204.306 40.6319 205.074 39.869 205.074 38.9349V27.1404C205.074 22.2722 208.133 18.6806 212.029 18.6806C216.172 18.6806 218.025 21.4105 218.025 25.562V40.6319H224.287C225.236 40.6319 226.001 39.869 226.001 38.9349V24.6713C226.001 17.201 222.286 13.4065 215.267 13.4065Z'
          fill='#005BAC'
        />
        <path
          d='M93.9106 13.4064C90.1302 13.4064 86.3551 14.7647 83.6551 17.6132V4.47095H77.4543C76.5019 4.47095 75.7373 5.22724 75.7373 6.16529V40.6318H81.998C82.9451 40.6318 83.715 39.8689 83.715 38.9348V27.1403C83.715 22.272 86.7721 18.6805 90.667 18.6805C94.8057 18.6805 96.6626 21.4103 96.6626 25.5619V40.6318H102.926C103.874 40.6318 104.646 39.8689 104.646 38.9348V24.6712C104.646 17.2009 100.925 13.4064 93.9106 13.4064Z'
          fill='#005BAC'
        />
        <path
          d='M116.12 15.2185C112.63 15.2185 109.797 17.3265 109.797 20.7811V40.631H116.056C117.002 40.631 117.773 39.8695 117.773 38.9353V15.2185H116.12Z'
          fill='#005BAC'
        />
      </svg>
    </div>
  )
}

export default HitronLogo
