import * as React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { getColorCode } from '../../utils/helper'
// import { Close } from '@mui/icons-material'

const MessageModal = ({ handleClose, data }: any) => {
  return (
    <Box textAlign={'center'} padding={2}>
      {/* <Box textAlign={'center'} display={'flex'} justifyContent={'end'} paddingRight={2}>
        <Close
          className='cursor-pointer'
          onClick={() => {
            handleClose()
          }}
        />
      </Box> */}
      <Box
        marginTop={2}
        sx={{
          maxHeight: '50vh',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <Typography variant='body1'>
          <b> Following GWs could not be added, as they were not found in the system:</b>
          {data.length !== 0
            ? data.map((obj: any, key: number) => {
                return (
                  <React.Fragment key={key}>
                    {obj}
                    {key === data.length - 1 ? '' : ', '}
                  </React.Fragment>
                )
              })
            : ''}
        </Typography>
      </Box>
      <div className='mt-4 mb-3'>
        <Button
          type='submit'
          variant='contained'
          size='medium'
          sx={{
            marginRight: '10px',
            background: getColorCode('infoBlue'),
            '&:hover': {
              backgroundColor: getColorCode('infoBlue')
            }
          }}
          onClick={handleClose}
        >
          Ok
        </Button>
      </div>
    </Box>
  )
}
export default MessageModal
