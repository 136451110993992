import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import React from 'react'
import { getColorCode } from '../../../../utils/helper'
import { Eye, EyeSlash } from 'iconsax-react'
import { Settings, SettingsKey, WiFiSection } from './WifiBasicSettings'

interface WiFiSectionComponentProps {
  type: WiFiSection
  showPassword: boolean
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>
  settings: Settings
  errors: any
  handleChange: (type: WiFiSection, field: SettingsKey, value: string) => void
  passwordModified: any
}

const RenderWiFiSection: React.FC<WiFiSectionComponentProps> = ({
  type,
  showPassword,
  setShowPassword,
  settings,
  errors,
  handleChange,
  passwordModified
}) => {
  return (
    <Box className='bg-white info-card-dev mb-4 pb-2'>
      <Box className='font-bold title pt-2 pl-10 border-b-2 pb-2 mb-2'>
        {settings.dualFrequencyIntegration ? 'WiFi Settings' : `WiFi ${type === 'wifi24' ? '2.4G' : '5G'} Settings`}
      </Box>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='SSID'
                value={settings[type].ssid}
                onChange={e => handleChange(type, 'ssid', e.target.value)}
                error={!!errors[type].ssid}
                helperText={errors[type].ssid}
                FormHelperTextProps={{
                  sx: { '&.Mui-error': { color: getColorCode('redToBlue') } }
                }}
                InputLabelProps={{
                  sx: {
                    '&.Mui-error': { color: getColorCode('redToBlue') }
                  }
                }}
                InputProps={{
                  sx: {
                    '&.Mui-error': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: getColorCode('redToBlue')
                      }
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors[type].encrypt}>
                <InputLabel sx={{ '&.Mui-error': { color: getColorCode('redToBlue') } }}>Encrypt</InputLabel>
                <Select
                  value={settings[type].encrypt}
                  label='Encrypt'
                  onChange={e => handleChange(type, 'encrypt', e.target.value)}
                  sx={{
                    '&.Mui-error': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: getColorCode('redToBlue')
                      }
                    },
                    color: {
                      '&.Mui-error': { color: getColorCode('redToBlue') }
                    }
                  }}
                >
                  <MenuItem value='none'>None</MenuItem>
                  <MenuItem value='psk2'>WPA2-PSK</MenuItem>
                </Select>
                {errors[type].encrypt && (
                  <FormHelperText sx={{ '&.Mui-error': { color: getColorCode('redToBlue') } }}>
                    {errors[type].encrypt}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {settings[type].encrypt !== 'none' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  value={settings[type].password}
                  onChange={e => handleChange(type, 'password', e.target.value)}
                  error={!!errors[type].password}
                  helperText={errors[type].password}
                  inputProps={{
                    maxLength: 63
                  }}
                  InputProps={{
                    endAdornment: passwordModified[type] ? (
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <Eye
                            size={20}
                            color={
                              process.env.REACT_APP_COLOR === 'hitron'
                                ? '#004c97'
                                : process.env.REACT_APP_COLOR === 'customer'
                                ? '#ff974c'
                                : '#6581fa'
                            }
                          />
                        ) : (
                          <EyeSlash size={20} />
                        )}
                      </IconButton>
                    ) : null,
                    sx: {
                      '&.Mui-error': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: getColorCode('redToBlue')
                        }
                      }
                    }
                  }}
                  FormHelperTextProps={{
                    sx: { '&.Mui-error': { color: getColorCode('redToBlue') } }
                  }}
                  InputLabelProps={{
                    sx: {
                      '&.Mui-error': { color: getColorCode('redToBlue') }
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default RenderWiFiSection
