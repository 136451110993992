import React from 'react'
import { getColorCode } from '../../../utils/helper'

const Update = () => {
  const color = getColorCode('lightblueToGray')

  return (
    <>
      <svg width='80' height='100' viewBox='0 0 24 24' fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3147 6.38254L16.322 7.84093C14.7684 6.22888 12.4119 5.59437 10.2218 6.27413C7.71513 7.05215 6.00301 9.37252 6.00017 11.9964C5.98685 12.5076 6.75948 12.5106 6.75016 11.9993C6.75271 9.70085 8.2467 7.67297 10.443 6.99129C12.4432 6.37046 14.5945 7.00164 15.9485 8.54857L14.1636 8.55807C13.6631 8.56076 13.6661 9.31161 14.1666 9.30887L16.7044 9.29498C16.9029 9.29389 17.0798 9.12937 17.0786 8.91555L17.0647 6.37966C17.0636 6.16674 16.8923 5.99442 16.6829 6.00014C16.4734 6.00585 16.3136 6.17649 16.3147 6.38254ZM17.25 12.0007C17.2475 14.2992 15.7534 16.3271 13.5571 17.0088C11.5569 17.6296 9.40566 16.9984 8.05161 15.4515L9.83649 15.442C10.337 15.4393 10.334 14.6885 9.83353 14.6912C8.98759 14.6959 7.47336 14.7041 7.29572 14.7051C7.09716 14.7062 6.92029 14.8707 6.92146 15.0845L6.93535 17.6204C6.93652 17.8333 7.10778 18.0057 7.3206 17.9999C7.52656 17.9942 7.68638 17.8236 7.68534 17.6175C7.68293 17.1314 7.67797 16.1591 7.67797 16.1591C9.23156 17.7712 11.5881 18.4057 13.7782 17.7259C16.2849 16.9479 17.997 14.6275 17.9998 12.0037C18.0065 11.7481 17.8166 11.6199 17.6264 11.6191C17.4361 11.6184 17.2453 11.7451 17.25 12.0007Z'
          fill={color}
        />
      </svg>
    </>
  )
}

export default Update
