import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { FC } from 'react'
import moment from 'moment/moment'
import { getColorCode } from '../../../utils/helper'

interface Props {
  data: any
  categories: string[]
}
const UploadSpeedChart: FC<Props> = ({ data, categories }) => {
  // const chartData = [42, 87, 56, 32, 71];

  const chartActualData: number[] = data
    ?.reverse()
    ?.filter((item: any) => item.result !== 'ERROR' && item.result !== 'OK')
    ?.map((item: any) => {
      return item.max_upload_speed
    })

  const chartCategories = categories
  // Calculate min, max, and average values
  const minValue = Math.min(...chartActualData)
  const maxValue = Math.max(...chartActualData)
  const averageValue = chartActualData?.reduce((sum, value) => sum + value, 0) / chartActualData?.length

  // Define the color rules based on conditions
  const colorRules = [
    {
      condition: (value: number) => value <= minValue,
      color: getColorCode('chartRedBlue')
    },
    {
      condition: (value: number) => value > minValue && value <= averageValue,
      color: getColorCode('chartDarkGrayBlue')
    },
    {
      condition: (value: number) => value > averageValue,
      color: getColorCode('chartGreenBlue')
    }
  ]

  const seriesData = chartActualData?.map(function (value, index) {
    const colorRule = colorRules.find(rule => rule.condition(value))
    return {
      name: chartCategories[index],
      y: value,
      color: colorRule?.color
    }
  })

  const options: any = {
    chart: {
      type: 'column',
      backgroundColor: '#ffffff',
      height: 250
    },
    title: {
      text: undefined
    },
    xAxis: {
      categories: chartCategories,
      title: {
        text: undefined
      },
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          const momentValue = moment(this.value + 'Z').local()
          return momentValue.format('hh:mm A')
        }
      },
      // labels: {
      //   enabled: false
      // },
      lineWidth: 2
    },
    yAxis: {
      title: {
        text: undefined
      },
      gridLineColor: 'transparent',
      lineWidth: 2,
      plotLines: [
        // {
        //   color: '#FF8181',
        //   width: 2,
        //   value: Math.min(...chartActualData),
        //   label: {
        //     text: 'Min',
        //     align: 'right',
        //     x: 0
        //   },
        //   zIndex: 5,
        //   dashStyle: 'dot'
        // },
        {
          color: '#6C83FF', // Line color for average
          width: 2,
          value: chartActualData?.reduce((sum, value) => sum + value, 0) / chartActualData.length,
          label: {
            text: 'Avg.',
            align: 'right',
            x: 0
          },
          zIndex: 5,
          dashStyle: 'dot'
        }
        // {
        //   color: '#A3D696', // Line color for max
        //   width: 2,
        //   value: Math.max(...chartActualData),
        //   label: {
        //     text: 'Max',
        //     align: 'right',
        //     x: 0
        //   },
        //   zIndex: 5,
        //   dashStyle: 'dot'
        // }
      ]
    },
    plotOptions: {
      bar: {
        pointWidth: 10, // Set the bar width to 30 pixels
        dataLabels: {
          show: false
        },
        legend: {
          enabled: false
        }
      },
      series: {
        pointWidth: 27
      }
    },
    series: [
      {
        name: 'Upload Speed',
        data: seriesData,
        color: '#A3D696'
      }
    ],
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        //@ts-ignore
        const timestamp =
          moment(this.x + 'Z')
            .local()
            .fromNow() +
          '(' +
          moment(this.x + 'Z')
            .local()
            .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`) +
          ')'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          Math.ceil(this.y as any) +
          'Mbps </span><br/>' +
          '<span> <b>when: </b>' +
          timestamp +
          '</span>' +
          '</div>'
        )
      }
    }
  }

  return (
    <div className='bg-layout-neutralBg px-3'>
      <h1 className='text-[16px] font-sf-semibold text-layout-textOnSurface mb-[32px] pt-6 '>Upload Speed</h1>

      <HighchartsReact highcharts={Highcharts} options={options} height={75} />

      <div className='speedTestChartDescription'>
        <div className='flex items-center gap-x-4'>
          <span className='text-[18px] font-sf-bold'>Min:</span>
          <span>{Math.ceil(minValue)} Mbps</span>
        </div>

        <div className='flex items-center gap-x-4'>
          <span className='text-[18px] font-sf-bold'>Max:</span>
          <span>{Math.ceil(maxValue)} Mbps</span>
        </div>

        <div className='flex items-center gap-x-4'>
          <span className='text-[18px] font-sf-bold'>Current:</span>
          <span>{Math.ceil(data?.reverse()[0]?.max_upload_speed)} Mbps</span>
        </div>
      </div>
    </div>
  )
}

export default UploadSpeedChart
