import { useState } from 'react'
import { Modal } from 'antd'
import { Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Formik } from 'formik'
// import { usePinVerification } from '../../../hook/parental-control'
import { SnackBarProps } from '../../../components/Device-table/Provisioning'
import { Snackbar } from '@mui/material'
import { getColorCode } from '../../../utils/helper'
import * as Yup from 'yup'
// import './index.scss'
import MuiAlert from '@mui/material/Alert'
function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const ChangePinModal = ({ isOpen, onClose, title, subTitle, onSubmit }: any) => {
  // const verifyPinMutation = usePinVerification()
  const resetPasswordSchema = Yup.object().shape({
    pincode: Yup.string()
      .required('Please enter PIN')
      .min(6, 'PC / CS PIN has to be 6 characters long.')
      .matches(/^(\d{6})$/, 'Please enter only numerical value')
  })
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed',
    severity: 'success'
  })
  const handleValidSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true)
    const data = { confirm_pincode: values.pincode, new_pincode: values.pincode }
    onSubmit(data)
    // verifyPinMutation.mutate(values, {
    //   onSuccess: (data: any) => {
    //     localStorage.setItem('isPCverified', 'true')
    // closeModal()
    //     actions.setSubmitting(false)
    //   },
    //   onError: (error: any) => {
    //     actions.setSubmitting(false)

    //     setSnackBarInfo({
    //       isOpen: true,
    //       message: 'Invalid pin code',
    //      type: 'redToRed',
    //       severity: 'error'
    //     })
    //   }
    // })
  }
  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      width={500}
      wrapClassName={'reset_wifi_wrapper'}
      centered={true}
    >
      <Formik
        initialValues={{
          pincode: ''
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleValidSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className='reset_wifi_body_wrapper device-tab'>
                <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
                  <h3
                    style={{
                      fontSize: '20px',
                      fontWeight: '600',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    {title}
                  </h3>
                  <Close
                    className='cursor-pointer'
                    onClick={() => {
                      onClose()
                      resetForm()
                    }}
                  />
                </div>
                {snackBarInfo.isOpen && (
                  <Snackbar
                    open={snackBarInfo.isOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={6000}
                    onClose={() =>
                      setSnackBarInfo({
                        isOpen: false,
                        message: snackBarInfo.message,
                        type: snackBarInfo.type,
                        severity: 'success'
                      })
                    }
                    sx={{ backgroundColor: 'transparent' }}
                  >
                    <div>
                      <Alert
                        severity={snackBarInfo.severity}
                        sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
                      >
                        {snackBarInfo.message}
                      </Alert>
                    </div>
                  </Snackbar>
                )}
                <div className='mb-2 row-gap-1 text-center'>
                  <div className='mt-1'>{subTitle}</div>
                  <div className='mb-3 mt-2'>
                    <input
                      type='password'
                      className='p-2 rounded-[10px] w-full border-[1px] verifypin-form-input'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pincode}
                      name='pincode'
                      placeholder='Please enter verify pin'
                      maxLength={6}
                      minLength={6}
                      style={
                        errors.pincode
                          ? { border: `1px solid ${getColorCode('redToBlue')}` }
                          : { border: `1px solid #ccc` }
                      }
                    />
                    {errors.pincode && (
                      <span
                        className='errorText'
                        style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                      >
                        {errors.pincode}
                      </span>
                    )}
                    <div className='mt-3'>
                      <Button
                        type='submit'
                        variant='contained'
                        size='medium'
                        sx={{
                          marginRight: '10px',
                          color: 'white',
                          background: getColorCode('infoBlue'),
                          '&:hover': {
                            backgroundColor: getColorCode('infoBlue')
                          }
                        }}
                        disabled={values.pincode?.length < 6}
                      >
                        Set
                      </Button>

                      {/* <Button
                        onClick={() => {
                          resetForm()
                          onClose()
                        }}
                        size='medium'
                        variant='outlined'
                        sx={{
                          color: getColorCode('infoBlue'),
                          borderColor: getColorCode('infoBlue')
                        }}
                      >
                        Cancel
                      </Button> */}
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ChangePinModal
