import axios from 'axios'
import { KEYCLOAK_CONFIG, apiUrl } from '../../app-config'
import { notifyError } from '../../components/Toaster'
import xcpemSec from '../../axiosInstances/xcpemSec'

export const fetchGatewayNotes = async ({ gwUid }: { gwUid: string }) => {
  try {
    const response = await xcpemSec.get(`/gateways_support_notes_list?gw_uid=eq.${gwUid}&order=timestamp.desc`)
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return error?.response?.status
      // localStorage.setItem('error', 'true')
      //  localStorage.clear()
      //  localStorage.clear()
      //  window.location.href = '/#/login'
    } else {
      notifyError(`Support notes list: ${error?.response?.data?.message || error?.message}`)
    }

    return null
  }
}
