import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { GatewayStats } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'

export const useQoeChart = (
  activeGw_Uid: string,
  activeTile: string | number,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['qoe-chart'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')
      // if (!gwuid || !activeTile || !timestamp) return

      // const endpoint = `/rpc/webapp_network_clients_stats?_gw_uid=gwc1e2e30fc4034889a131cf68cbc28b7d&_mac=8A:4C:86:DB:78:6D&_timestamp_from=2023-10-9T18:30:00.000Z`
      const endpoint = `/rpc/webapp_network_clients_stats?_gw_uid=${gwuid}&_mac=${activeTile}&_timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`QoE chart: ${error?.response?.data?.message || error?.message}`)
        }

      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
