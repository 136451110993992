import React from 'react'
import { Box, Typography } from '@mui/material'

const DeleteAccount = () => {
  return (
    <>
      <Box maxWidth={'700px'} margin={'auto'}>
        <Box margin={'40px 0px'} textAlign={'center'}>
          <Typography variant='h4'>Deleting Account</Typography>
        </Box>
        <Box>
          <Typography>
            Deleting your account will permanently erase all of your information, devices, reports, and configurations
            from our database. This action cannot be reversed.
          </Typography>
        </Box>
        <Box marginTop={'20px'}>
          <Typography>To delete your account, you can follow one of the following processes:</Typography>
        </Box>
        <Box marginTop={'30px'}>
          <Typography variant='subtitle1' style={{ textDecoration: 'underline', marginBottom: '20px' }}>
            Option 1:
          </Typography>
          <ol style={{ listStyle: 'auto', padding: 'revert' }}>
            <li>Go to the App</li>
            <li>Login to your account</li>
            <li>
              Tap on the <b>Settings</b> tile
            </li>
            <li>
              Locate the <b>Delete Account</b> link under the <b>User</b> section
            </li>
            <li>
              Click on the <b>Delete Account</b> link
            </li>
            <li>
              Click on the <b>Delete</b> button to confirm the Delete action
            </li>
            <li>Your account will be deleted.</li>
          </ol>
        </Box>
        <Box marginTop={'30px'}>
          <Typography variant='subtitle1' style={{ textDecoration: 'underline', marginBottom: '20px' }}>
            Option 2:
          </Typography>
          <ol style={{ listStyle: 'auto', padding: 'revert' }}>
            <li>Go to the App</li>
            <li>Login to your account</li>
            <li>
              Tap on the <b>Settings</b> tile
            </li>
            <li>
              Locate the <b>Feedback</b> link under the <b>User</b> section
            </li>
            <li>Send a request through the Feedback email to Support </li>
            <li>requesting to delete your account</li>
            <li>Support Admin will delete your account.</li>
          </ol>
        </Box>
        <Box marginTop={'30px'}>
          <Typography variant='subtitle1' style={{ textDecoration: 'underline', marginBottom: '20px' }}>
            Option 3:
          </Typography>
          <ol style={{ listStyle: 'auto', padding: 'revert' }}>
            <li>
              Send email to your Support admin giving your Account <b>Email address</b> and <b>Gateway UID</b> and
              request for Account Deletion
            </li>
            <li>Support Admin will delete your account</li>
            <li>
              <b>Note:</b> You can find your gateway UID on the sticker located at the bottom of your device. It is
              typically printed alongside other device information.
            </li>
          </ol>
        </Box>
        <Box marginTop={'30px'} marginBottom={'50px'}>
          <Typography style={{ fontWeight: 'bold' }}>
            Once the process is completed, you will receive a notification email at the email address provided.
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default DeleteAccount
