import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { apiUrl } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

export const useCellularData = ({
  gw_uid
}: any): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['use-Cellular-Data'],
    async () => {
      try {
        let url = ''

        url = `${apiUrl}/api/v4/iot/lte/status?gw_uuid=${gw_uid}`
        const response = await xcpemSec.get<any[]>(url)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Wifi Coverage: ${error?.response?.data?.message || error?.message}`)
          return error
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['use-Cellular-Data'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
