import { useEffect, useState } from 'react'
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Modal } from 'antd'
import { Add, Edit2, SearchStatus, Trash } from 'iconsax-react'
import { useSelector } from 'react-redux'
import CustomerModal from '../../components/Customer-table/CustomerModal'
import { useGetMSO } from '../../hook/useAllCustomers'
import { RootState } from '../../redux/deviceStatistics/types'
import { useSessionContex } from '../../SessionContex'
import { getColorCode } from '../../utils/helper'
import AddDevice from './AddDevice'
import DeleteDevice from './DeleteDevice'
import Deprovision from './Deprovision'
import DeviceDetails from './DeviceDetails'
import Registor from './Registor'

const MsoDetails = ({
  details,
  setCustomerOpenModal,
  setEdit,
  setEditData,
  setSnackBarInfo,
  setReCall,
  handleClose,
  setDeleteMSOModal,
  setDisplaySearch,
  displaySearch
}: any) => {
  const { openModal, setOpenModal } = useSessionContex()
  const [deviceOpenModal, setDeviceOpenModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [register, setRegister] = useState(false)
  const [deprovision, setDeprovision] = useState(false)
  const [deviceGwId, setDeviceGwId] = useState('')
  const [deviceGwEmail, setDeviceGwEmail] = useState('')
  const [callDeviceDetail, setCallDeviceDetail] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [showCloseIcon, setShowCloseIcon] = useState(false)
  const [displayButton, setDisplayButton] = useState(true)
  const [deviceArray, setDeviceArray] = useState([])

  const { data: MSOData, isFetching, isLoading, refetch } = useGetMSO(details?.id)
  const defaultUserData = useSelector((state: RootState) => state.user)

  const [data, setData] = useState<any>({
    customer_address: '',
    customer_contact_person: '',
    customer_email: '',
    customer_name: '',
    customer_phone: '',
    id: 1,
    white_label: ''
  })

  useEffect(() => {
    // setData(details)
    if (details.id) {
      refetch()
    }
  }, [details])

  useEffect(() => {
    if (MSOData === 401) {
      !openModal && setOpenModal(true)
    } else {
      if (MSOData && MSOData.length !== 0) {
        setData(MSOData[0])
        setCallDeviceDetail(true)
      }
    }
  }, [MSOData])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setShowCloseIcon(false)
    if (value !== '') {
      setInputValue(value)
    } else {
      setInputValue('')
    }
  }
  const submitAPI = (name: any) => {
    if (name === 'search') {
      if (inputValue !== '') {
        setCallDeviceDetail(true)
        setShowCloseIcon(true)
      }
    }
    if (name === 'close') {
      setCallDeviceDetail(true)
      setShowCloseIcon(false)
      setInputValue('')
    }
  }
  return (
    <>
      <Box className='bg-white m-2 rounded'>
        <Box className='text-end mt-1'>
          <Close
            className='cursor-pointer'
            onClick={() => {
              handleClose()
              setDisplaySearch(false)
            }}
          />
        </Box>
        <Box
          className='p-4 pt-0'
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          flexWrap={'wrap'}
        >
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              {isFetching || isLoading ? (
                <Skeleton variant='text' width={'50px'} />
              ) : (
                <>
                  <b>{data?.white_label || data?.customer_name || '-'}</b>
                  &nbsp; &nbsp;
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#707e8c'
                        }
                      }
                    }}
                    title={<Typography>Edit MSO</Typography>}
                  >
                    <Edit2
                      size={'18px'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCustomerOpenModal(true)
                        setEdit(true)
                        setEditData(data)
                      }}
                    />
                  </Tooltip>
                  {defaultUserData.deleteMSO &&
                  defaultUserData.deleteMSO !== null &&
                  defaultUserData.deleteMSO === 1 ? (
                    <>
                      &nbsp; &nbsp;
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={<Typography>Delete MSO</Typography>}
                      >
                        <Trash
                          size={'18px'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setDeleteMSOModal(true)
                            setEditData(data)
                          }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Box>
            <Box display={'flex'}>
              {isFetching || isLoading ? (
                <Skeleton variant='text' width={'80%'} />
              ) : (
                <>{data?.customer_address}</> || '-'
              )}
            </Box>
            <Box>
              {isFetching || isLoading ? (
                <Skeleton variant='text' width={'80%'} />
              ) : (
                <>
                  {data?.customer_contact_person}
                  {data?.customer_email ? (
                    <>
                      {data?.customer_contact_person ? <> &nbsp; | &nbsp;</> : ''} {data?.customer_email}
                    </>
                  ) : (
                    ''
                  )}
                  {data?.customer_phone ? (
                    <>
                      {data?.customer_email || data?.customer_contact_person ? <> &nbsp; | &nbsp;</> : ''}
                      {data?.customer_phone}
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box display={'flex'} flexWrap={'wrap'}>
            {displaySearch && (
              <Box
                display={'flex'}
                alignItems={'center'}
                border={`1px solid ${isFocused ? getColorCode('purpleBlue') : '#ccc'}`}
                borderRadius={'10px'}
                padding={'2px 10px'}
                margin={'10px 10px 0px 0px'}
              >
                <input
                  type='text'
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={() => {
                    if (inputValue === '') setIsFocused(false)
                  }}
                  onFocus={() => {
                    setIsFocused(true)
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      submitAPI('search')
                    }
                  }}
                  placeholder={'Search GWUID or email'}
                  disabled={displayButton || isFetching || isLoading}
                  // style={{
                  //   borderRadius: inputValue !== '' && isFocused ? `15px 15px 0px 0px` : '15px'
                  // }}
                />
                {showCloseIcon ? (
                  <Add
                    color={isFocused ? getColorCode('purpleBlue') : '#000000'}
                    onClick={() => submitAPI('close')}
                    style={{ transform: 'rotate(45deg)' }}
                  />
                ) : (
                  <SearchStatus
                    color={isFocused ? getColorCode('purpleBlue') : '#000000'}
                    onClick={() => submitAPI('search')}
                  />
                )}
              </Box>
            )}
            <Button
              variant='contained'
              size='medium'
              sx={{
                background: getColorCode('infoBlue'),
                margin: '10px 20px 0px 20px',
                textWrap: 'nowrap',
                '&:hover': {
                  backgroundColor: getColorCode('infoBlue')
                }
              }}
              onClick={() => setDeviceOpenModal(true)}
              disabled={displayButton || isFetching || isLoading}
            >
              Add Devices
            </Button>
            <Button
              variant='contained'
              size='medium'
              sx={{
                background: getColorCode('infoBlue'),
                margin: '10px 20px 0px 10px',
                textWrap: 'nowrap',
                '&:hover': {
                  backgroundColor: getColorCode('infoBlue')
                }
              }}
              onClick={() => setDeleteModal(true)}
              disabled={deviceArray.length === 0 || displayButton || isFetching || isLoading}
            >
              Remove Devices
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <DeviceDetails
          setDisplaySearch={setDisplaySearch}
          displaySearch={displaySearch}
          setRegister={setRegister}
          setDeprovision={setDeprovision}
          setDeviceGwId={setDeviceGwId}
          setDeviceGwEmail={setDeviceGwEmail}
          MSOData={MSOData}
          setCallDeviceDetail={setCallDeviceDetail}
          callDeviceDetail={callDeviceDetail}
          inputValue={inputValue}
          setDisplayButton={setDisplayButton}
          deviceArray={deviceArray}
          setDeviceArray={setDeviceArray}
        />
      </Box>
      {deviceOpenModal && (
        <CustomerModal
          open={deviceOpenModal}
          handleClose={() => {
            setDeviceOpenModal(false)
          }}
        >
          <AddDevice
            handleClose={() => {
              setDeviceOpenModal(false)
            }}
            MSOData={MSOData}
            setCallDeviceDetail={setCallDeviceDetail}
            setSnackBarInfo={setSnackBarInfo}
            setReCall={setReCall}
          />
        </CustomerModal>
      )}
      {deleteModal && (
        <Modal open={deleteModal} footer={null} closable={false} width={700} centered={true} keyboard={true}>
          {/* <CustomerModal
          open={deleteModal}
          handleClose={() => {
            setDeleteModal(false)
          }}
        > */}
          <DeleteDevice
            handleClose={() => {
              setDeleteModal(false)
            }}
            deviceArray={deviceArray}
            setDeviceArray={setDeviceArray}
            setCallDeviceDetail={setCallDeviceDetail}
            setSnackBarInfo={setSnackBarInfo}
            setReCall={setReCall}
          />
          {/* </CustomerModal> */}
        </Modal>
      )}
      {register && (
        <CustomerModal
          open={register}
          handleClose={() => {
            setRegister(false)
            setDeviceGwId('')
          }}
        >
          <Registor
            handleClose={() => {
              setRegister(false)
              setDeviceGwId('')
            }}
            deviceGwId={deviceGwId}
            setCallDeviceDetail={setCallDeviceDetail}
            setSnackBarInfo={setSnackBarInfo}
          />
        </CustomerModal>
      )}
      {deprovision && (
        <CustomerModal
          open={deprovision}
          handleClose={() => {
            setDeprovision(false)
            setDeviceGwId('')
            setDeviceGwEmail('')
          }}
        >
          <Deprovision
            handleClose={() => {
              setDeprovision(false)
              setDeviceGwId('')
              setDeviceGwEmail('')
            }}
            deviceGwId={deviceGwId}
            deviceGwEmail={deviceGwEmail}
            setCallDeviceDetail={setCallDeviceDetail}
            setSnackBarInfo={setSnackBarInfo}
          />
        </CustomerModal>
      )}
    </>
  )
}
export default MsoDetails
