import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { Column, TableDataRow } from './CellularModal'
import Loader from '../../../../components/Loader'

interface CellularUsageTableProps {
  filteredColumns: Column[]
  tableData: TableDataRow[]
  isFetching?: boolean
}

const CellularUsageTable: React.FC<CellularUsageTableProps> = ({ filteredColumns, tableData, isFetching }) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ mt: 2, border: '0.5px solid #90969D', borderRadius: '15px', maxHeight: 400 }}
      >
        <Table stickyHeader aria-label='cellular usage table'>
          <TableHead>
            <TableRow>
              {filteredColumns.map(column => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  sx={{ fontWeight: '700', color: '#3D4854' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell align='center' colSpan={filteredColumns.length}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : tableData?.length === 0 ? (
              <TableRow>
                <TableCell align='center' colSpan={filteredColumns.length}>
                  No Record Found..
                </TableCell>
              </TableRow>
            ) : (
              tableData?.map(row => (
                <TableRow key={row.id}>
                  {filteredColumns.map(column => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CellularUsageTable
