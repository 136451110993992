import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const PageNotFound = () => {
  return (
    <Box
      sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
    >
      <Typography variant='h1'>404</Typography>
      <Typography variant='h6'>Not Found</Typography>
      <span style={{ marginTop: '10px' }}>The requested URL was not found on this server.</span>
    </Box>
  )
}

export default PageNotFound
