import {
  FC,
  // useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
// import ParentalControlTable from '../../../../components/parental-components/table/table'
import ParentalLayout from '../../../../Layouts/parentalLayout/parental-layout'
import { useGatewayInfo } from '../../../../hook/useGatewayInfo'
import DevicesList from '../../../../components/parental-components/devices-list/devices-list'

import TimeSuperVision from '../../../../components/customers/cards/time-supervision'
// import SocialSupervisionChart from "../../../components/parental-components/charts/social-supervision-radial-chart";
// import SocialPieChart from "../../../components/parental-components/charts/social-supervision-pie-chart";
// import SocialSupervisionTable from "../../../../components/parental-components/table/social-supervision-table";
// import { BreadcrumbContext } from '../../../../contexts/BreadcrumbContext'
import './styles.scss'
import ParentalControlTable from '../../../../components/parental-components/table/table'
import SocialSupervisionTable from '../../../../components/parental-components/table/social-supervision-table'
import {
  getColorCode
  //  currentISOTime,
  // startOfMonth, startOfWeek
} from '../../../../utils/helper'
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuTrigger
// } from '../../../../Layouts/AnimatedLayout/DropDown/dropdown-menu'
// import DownSideArrow from '../../../../common-images/app-logo/downside-arrow'
// import dayjs from 'dayjs'
import moment from 'moment'
import SupervisionTable from '../../../../components/parental-components/table/supervisionTable'
// import SessionTimeOutModal from '../../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../../SessionContex'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Loader from '../../../../components/Loader'

// interface Props {
//   serialNumber: string
// }

const Supervision: FC = () => {
  const { id: serialNumber, dataFilter } = useParams()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  //@ts-ignore
  const { data: gatewayInfo, refetch, isFetching } = useGatewayInfo(serialNumber as any)
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (reCall) {
      refetch()
      // setFilteringDevice(filteringDevice)
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])
  // useEffect(() => {

  //   if(serialNumber && gatewayInfo){
  //     const newBreadcrumbData = [
  //       {label: 'Home', href: `/dashboard`},
  //       {label: 'Customers', href: `/dashboard/${gatewayInfo.gw_uid}`},
  //       {label: gatewayInfo?.email || '-', href: `/dashboard/${gatewayInfo?.gw_uid}`},
  //       {label: 'Parental Control', href: `/dashboard/${gatewayInfo?.gw_uid}`},
  //       {label: 'Supervision', href: `/dashboard/${gatewayInfo?.gw_uid}?email=${gatewayInfo?.email}`}
  //     ]
  //     const filters: any = []
  //     updateFilters(filters)
  //     updateBreadcrumbData(newBreadcrumbData)
  //   }
  // }, [serialNumber,gatewayInfo])
  const [filterLabel, setFilterLabel] = useState<string>('today')

  const CartData = [
    {
      name: 'Today',
      value: 'today'
    },
    {
      name: 'Last 7 Days',
      value: 'thisWeek'
    },
    {
      name: 'Last 30 Days',
      value: 'thisMonth'
    }
  ]

  const [activeTab, setActiveTab] = useState<string>('webMonitoring')
  // const [hours] = useState<number>(dayjs().tz(timeZone).diff(dayjs().tz(timeZone).startOf('day'), 'hour'))
  const [fromDate, setFromDate] = useState<any>(
    moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss')
  )
  const [filteringDevice, setFilteringDevice] = useState<number>(0)
  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const onDeviceChange = (deviceId: number) => {
    setFilteringDevice(deviceId)
  }
  const handleFilterChange = (e: any) => {
    if (e.target.value === 'thisMonth') {
      setFromDate(moment.utc(moment.tz(moment().subtract(1, 'month'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'thisWeek') {
      setFromDate(moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'today') {
      setFromDate(moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    }
    setFilterLabel(e.target.value)
  }
  return (
    <ParentalLayout serialNumber={serialNumber as any} useSidebar='Parental'>
      <div className='supervision-wrapper'>
        <div className='flex flex-row overflow-x-auto'>
          {gatewayInfo && (
            <DevicesList
              email={gatewayInfo.email}
              onChange={onDeviceChange}
              setActiveDevice={setFilteringDevice}
              filteringDevice={filteringDevice}
            />
          )}
        </div>

        <div className='bg-white supervision-inner-block'>
          <div className='flex flex-wrap justify-between pl-5'>
            {/* <h1 className='text-[24px] p-[30px] bg-white font-sf-semibold text-layout-textOnSurface inner-block-title'>
              Supervision
            </h1> */}
            <div className='inline-flex w-full justify-between items-center time-supervision-inner-wrapper'>
              {/* <span className='text-[24px] font-sf-bold time-supervision-title'>Time Supervision ( in minutes )</span> */}
              <h1 className='text-[24px] p-[30px] bg-white font-sf-semibold text-layout-textOnSurface inner-block-title'>
                Supervision
              </h1>
              {/* <DropdownMenu>
                <DropdownMenuTrigger className='dropdown-btn flex'>
                  {filterLabel}
                  <span className='down-arrow'>
                    <DownSideArrow />
                  </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent className='drop-down'>
                  {CartData.map((option: any, index: number) => {
                    return (
                      <DropdownMenuItem
                        className='drop-down-option'
                        key={`option_${index}_${option}`}
                        onClick={(e: any) => handleFilterChange(option)}
                      >
                        {option.name}
                      </DropdownMenuItem>
                    )
                  })}
                </DropdownMenuContent>
              </DropdownMenu> */}
              {/* <div className='inline-flex w-full justify-between items-center time-supervision-inner-wrapper mb-2'>
                <span className='text-[24px] font-sf-bold time-supervision-title'>Web Supervision</span> */}
              <div className='w-40 mt-2'>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={filterLabel}
                    label='Filter'
                    onChange={handleFilterChange}
                    className='text-uppercase'
                  >
                    {CartData.map((option: any, index: number) => {
                      return (
                        <MenuItem value={option.value} className='text-uppercase' key={`option_${index}_${option}`}>
                          {option.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className='flex flex-wrap justify-start gap-x-[30px] gap-y-[20px]  w-full px-[36px] mb-3 inner-block-tab-main'>
            <div
              onClick={() => handleTabChange('webMonitoring')}
              className={`${
                activeTab === 'webMonitoring' ? 'pc-category-button-active' : 'pc-category-button-inactive'
              }  usefont `}
              style={{
                background: `${activeTab === 'webMonitoring' ? getColorCode('infoBlue') : '#fff'}`
              }}
            >
              Web Monitoring
            </div>

            <div
              onClick={() => handleTabChange('webSupervision')}
              className={`${
                activeTab === 'webSupervision' ? 'pc-category-button-active' : 'pc-category-button-inactive'
              }  usefont `}
              style={{
                background: `${activeTab === 'webSupervision' ? getColorCode('infoBlue') : '#fff'}`
              }}
            >
              Web Supervision
            </div>

            <div
              onClick={() => handleTabChange('socialSupervision')}
              className={`${
                activeTab === 'socialSupervision' ? 'pc-category-button-active' : 'pc-category-button-inactive'
              }  usefont `}
              style={{
                background: `${activeTab === 'socialSupervision' ? getColorCode('infoBlue') : '#fff'}`
              }}
            >
              Social Supervision
            </div>

            <div
              onClick={() => handleTabChange('timeSupervision')}
              className={`${
                activeTab === 'timeSupervision' ? 'pc-category-button-active' : 'pc-category-button-inactive'
              }  usefont `}
              style={{
                background: `${activeTab === 'timeSupervision' ? getColorCode('infoBlue') : '#fff'}`
              }}
            >
              Time Supervision
            </div>
          </div>
          {/*<SocialSupervisionChart chartData={chartData}/>*/}
          {isFetching && <Loader />}
          {activeTab === 'timeSupervision' && (
            <TimeSuperVision
              email={gatewayInfo?.email as any}
              filteringDevice={filteringDevice}
              dataFilter={(dataFilter as any) || filterLabel}
              fromDates={fromDate}
            />
          )}
          {activeTab === 'socialSupervision' && gatewayInfo && (
            <>
              <SocialSupervisionTable
                email={gatewayInfo.email}
                dataFilter={(dataFilter as any) || filterLabel}
                deviceFilter={filteringDevice}
                fromDate={fromDate}
              />
            </>
          )}
          {activeTab === 'webMonitoring' && gatewayInfo && (
            <>
              <ParentalControlTable
                showSocialChart={true}
                email={gatewayInfo.email}
                dataFilter={(dataFilter as any) || filterLabel}
                deviceFilter={filteringDevice}
                fromDate={fromDate}
              />
            </>
          )}
          {activeTab === 'webSupervision' && gatewayInfo && (
            <SupervisionTable
              showSocialChart={false}
              email={gatewayInfo.email}
              dataFilter={(dataFilter as any) || filterLabel}
              deviceFilter={filteringDevice}
              fromDate={fromDate}
            />
          )}
        </div>
      </div>
    </ParentalLayout>
  )
}

export default Supervision
