import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { getColorCode, getRandomInt } from '../../../../utils/helper'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const SignalQuality = ({ data }: any) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const convertTimestampFormat = (timestamp: any) => {
    const formattedTimestamp = dayjs.utc(timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()
    return formattedTimestamp
  }
  const signal_quality_chart_data =
    data?.map((item: any) => {
      // var localtime = moment.utc(item.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)
      var localtime = convertTimestampFormat(item.timestamp)

      // return [Date.parse(localtime), item.snr]
      return [localtime?.getTime(), item.snr]
      // return [Date.parse(item.timestamp), item.snr]
    }) || []

  const signal_quality_options = {
    chart: {
      type: 'areaspline'
      // height: 204
      // marginTop: 40
    },
    title: {
      text: 'Signal Quality',
      align: 'left'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 0,
      backgroundColor: '#f0f8ff00'
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1 * 60 * 60 * 1000, // 3 hours in milliseconds
      labels: {
        formatter: function (this: any) {
          return moment(this.value).format('hh:mm A')
        }
      },
      dateTimeLabelFormats: {
        hour: '%I:%M %p', // Format for hours
        day: '%d/%m %H:%M' // Format for days
      }
    },
    // xAxis: {
    //   type: 'datetime',
    //   tickInterval: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    //   dateTimeLabelFormats: {
    //     hour: '%I:%M %p', // Format with AM/PM
    //     day: '%I:%M %p' // Format with AM/PM
    //   }
    // },
    yAxis: {
      title: {
        text: ''
      },
      gridLineColor: 'transparent',
      lineWidth: 1, // Set the width of the Y-axis line
      lineColor: '#333',
      gridLineWidth: 0,
      // height: '120px',
      labels: {
        formatter: function (this: any) {
          return this.value + ' db'
        }
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointStart: 0
      },
      areaspline: {
        fillOpacity: 0.5
      }
    },
    series: [
      {
        name: 'Signal Quality',
        data: signal_quality_chart_data || [],
        color: getColorCode('chartPurpleDarkPink'),
        pointPlacement: 'on'
      }
    ],
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const timestamp =
          moment(this.x).local().fromNow() +
          '(' +
          moment(this.x).format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm:ss A`) +
          ')'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          (this.y as any).toFixed(2) +
          '(db)</span><br/>' +
          '<span> <b>when: </b>' +
          timestamp +
          '</span>' +
          '</div>'
        )
      }
    }
  }
  return <HighchartsReact highcharts={Highcharts} options={signal_quality_options} />
}

export default SignalQuality
