import { FC, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { getColorCode, getRandomInt } from '../../../../utils/helper'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const QualityOfExperience = ({ data }: any) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const qoe_chart_data =
    data?.map((item: any) => {
      var localtime = dayjs.utc(item.timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()

      return [localtime.getTime(), item.qoe_score]
      // return [Date.parse(item.timestamp), item.qoe_score]
    }) || []
  const highQoeData = qoe_chart_data?.map((i: any) => {
    if (i[1] === 3) {
      return i
    } else {
      return [i[0], null]
    }
  })
  const mediamQoeData = qoe_chart_data?.map((i: any) => {
    if (i[1] === 2) {
      return i
    } else {
      return [i[0], null]
    }
  })
  const lowQoeData = qoe_chart_data?.map((i: any) => {
    if (i[1] === 1) {
      return i
    } else {
      return [i[0], null]
    }
  })

  const options = {
    chart: {
      type: 'areaspline'
      // height: 200
      // marginTop: 40
    },
    title: {
      text: 'Quality of Experience',
      align: 'left'
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 0,
      backgroundColor: '#f0f8ff00'
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1 * 60 * 60 * 1000, // 3 hours in milliseconds
      labels: {
        formatter: function (this: any) {
          return moment(this.value).format('hh:mm A')
        }
      },
      dateTimeLabelFormats: {
        hour: '%I:%M %p', // Format for hours
        day: '%d/%m %H:%M' // Format for days
      }
    },
    // xAxis:   {
    //   type: 'datetime',
    //   tickInterval: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    //   dateTimeLabelFormats: {
    //     hour: '%I:%M %p', // Format with AM/PM
    //     day: '%I:%M %p' // Format with AM/PM
    //   }
    // },
    yAxis: {
      title: {
        text: ''
      },
      gridLineColor: 'transparent',
      lineWidth: 1,
      labels: {
        formatter: function (this: any) {
          return this.value === 1 ? 'Low' : this.value === 2 ? 'Medium' : this.value === 3 ? 'High' : ''
        }
      },
      gridLineWidth: 0,
      min: 0,
      // max: 3,
      tickInterval: 1
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointStart: 10
      },
      areaspline: {
        fillOpacity: 0.5
      }
    },
    series: [
      {
        name: 'High',
        data: highQoeData || [],
        color: getColorCode('chartGreenPink')
      },
      {
        name: 'Medium',
        data: mediamQoeData || [],
        color: getColorCode('chartPurpleMahendi')
      },
      {
        name: 'Low',
        data: lowQoeData || [],
        color: getColorCode('chartRedBlue')
      }
    ],
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: any) {
        const timestamp =
          // moment.utc(this.x).fromNow() + '(' + moment.utc(this.x).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm`) + ')'
          // moment.utc(this.x).fromNow() +
          moment(this.x).local().fromNow() +
          '(' +
          moment(this.x).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm:ss A`) +
          ')'
        const type = this.y === 1 ? 'Low qoe' : this.y === 2 ? 'Medium qoe' : this.y === 3 ? 'High qoe' : '0'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          `${type}` +
          ' </b></span></br>' +
          '<span>' +
          // (this.point.options.y as any) +
          '<span> <b>when: </b>' +
          timestamp +
          '</span>' +
          '</div>'
        )
      }
    }
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default QualityOfExperience
