import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { apiUrl } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { GatewayServices } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'

export const useGatewayServices: any = (
  activeGw_Uid: string
): {
  data: GatewayServices | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gateway-services'],
    async () => {
      const gw_uid = activeGw_Uid || localStorage.getItem('gwuid')
      if (!gw_uid) return
      const endpoint = `${apiUrl}/webapp_gw_srv_list?gw_uid=eq.${gw_uid}`
      try {
        const response = await xcpemSec.get<GatewayServices[]>(endpoint)
        return response.data[0]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`GW srv list: ${error?.response?.data?.message || error?.message}`)
        }
        
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['gateway-services'] as readonly unknown[])
    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const usePutGatewayServices = () => {
  const putRequest = async ({ gw_uid, payload }: any) => {
    const gw_uida = gw_uid || localStorage.getItem('gwuid')
    const endpoint = `${apiUrl}/webapp_gw_srv_list?gw_uid=eq.${gw_uida}`

    try {
      const response = await xcpemSec.patch(endpoint, payload)
      return response.data
    } catch (error: any) {
      notifyError(`GW srv list: ${error?.response?.data?.message || error?.message}`)
      throw new Error(error?.response?.data?.message || error?.message)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error, isSuccess } = useMutation(putRequest)

  return { mutate, isLoading, error, isSuccess }
}
