import { useQuery, useQueryClient } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'

export const useGatewayInfo = (
  activeGw_Uid: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')
  const [data, setData] = useState<any | undefined>(undefined)
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const { isLoading, error, isFetched, refetch } = useQuery(
    ['gateway-info', gwuid],
    async () => {
      if (!gwuid) return
      const endpoint = `/webapp_dashboard_all_devices?gw_uid=eq.${gwuid}`

      try {
        const response = await xcpemSec.get<any[]>(endpoint)
        return (response.data.length === 0 ? [] : response.data[0]) || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`All devices: ${error?.response?.data?.message || error?.message}`)
        }
        
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
        setTimeout(() => {
          setIsFetching(false)
        }, 500)
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    // Manually clear the previous data
    setData(undefined)
    setIsFetching(true)
    // Invalidate the query cache
    await queryClient.invalidateQueries(['gateway-info'] as readonly unknown[])

    // Trigger the refetch
    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
