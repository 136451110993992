import React from 'react'
import PublicReport from './ReportsTabs/PublicReport'
import EmbedReport from './ReportsTabs/EmbedReport'
import CustomeTabs from '../../components/CustomeTabs'

const ReportsPage = () => {
  const tabsList = [
    {
      label: 'Publish To Web',
      testId: 'testid_public_report',
      tab: (
        <>
          <PublicReport />
        </>
      )
    },
    {
      label: 'Secure Embed',
      testId: 'testid_embed_report',
      tab: (
        <>
          <EmbedReport />
        </>
      )
    }
  ]

  return (
    <>
      <CustomeTabs tabsList={tabsList} className='gate_way_logs' />
    </>
  )
}

export default ReportsPage
