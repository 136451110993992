import React, { ChangeEvent, useEffect, useState } from 'react'
import Firmware from '../../../../common-images/app-logo/customer-images/Firmware'
import OnlineOfflineImage from '../../../../common-images/onlineOfflineImage'
import Pagination from '../../../../components/Pagination/index'
import moment from 'moment'
import { useGatewayActivityLogs, useGatewayAllActivityLogs } from '../../../../hook/useGatewayLogs'
import Loader from '../../../../components/Loader'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { useSelector } from 'react-redux'
import { useSessionContex } from '../../../../SessionContex'
import { getColorCode } from '../../../../utils/helper'
import { Tooltip } from '@mui/material'

const DeviceLog = () => {
  const activeGw_Uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState<number>(6)
  const [offset, setOffset] = useState<number>(0)

  const { data, isFetching, refetch } = useGatewayActivityLogs(activeGw_Uid || '', limit, offset)
  const { data: allData, refetch: allGetwayRefech } = useGatewayAllActivityLogs(activeGw_Uid || '')
  useEffect(() => {
    refetch()
  }, [offset, limit, activeGw_Uid])
  useEffect(() => {
    if (reCall) {
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  useEffect(() => {
    if (allData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [allData])
  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])
  useEffect(() => {
    allGetwayRefech()
  }, [])
  useEffect(() => {
    allGetwayRefech()
  }, [activeGw_Uid])

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage)

    const newOffset = (newPage - 1) * limit
    setOffset(newOffset)
  }

  return (
    <>
      <div className='container'>
        {isFetching ? (
          <Loader />
        ) : data?.length > 0 ? (
          data?.map((activity: any, index: any) => (
            <div key={index} className='row active-activity-details-cls mb-1'>
              <div className='col-8'>
                <div className='row'>
                  <div className='col-12 d-flex'>
                    <div className='border activity-logo-cls'>
                      <Firmware />
                    </div>
                    <div className='ps-2'>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={activity?.event}
                      >
                        <label className='activities-label-cls' title={activity?.event}>
                          {activity?.event}
                        </label>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#707e8c'
                            }
                          }
                        }}
                        title={activity?.derscription}
                      >
                        <p className='activities-desc-cls'>
                          {activity?.derscription.length > 20
                            ? activity?.derscription.substring(0, 20) + '...'
                            : activity?.derscription}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4 pt-2 ps-4  d-flex'>
                <label className='activities-desc-cls pe-2'>
                  {
                    // moment
                    //   .tz(moment.utc(activity.timestamp), timeZone)
                    //   .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                    moment.utc(activity?.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                  }
                </label>
                {/* <label>
                  <OnlineOfflineImage fillColor={getColorCode('Online')} width='10px' height='10px' />
                </label> */}
              </div>
            </div>
          ))
        ) : (
          <div className='no-data-found text-center pt-4' style={{ color: getColorCode('redToBlue') }}>
            No data found
          </div>
        )}
      </div>
      <div className='fixed-pagination-container'>
        {allData && allData?.length > 0 && (
          <Pagination
            onePage={limit}
            totalPages={allData?.length}
            handleSetCurrentCount={handleChangePage}
            displayTotal={true}
          />
        )}
      </div>
    </>
  )
}

export default DeviceLog
