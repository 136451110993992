import { useParams } from 'react-router-dom'
import ParentalLayout from '../../../Layouts/parentalLayout/parental-layout'
import { useContext, useEffect, useState } from 'react'
import { BreadcrumbContext } from '../../../contexts/breadcrumbData'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import DevicesList from '../../../components/parental-components/devices-list/devices-list'
import CyberTable from '../../../components/cyber-security/cyber-table/cyber-table'
import { useSessionContex } from '../../../SessionContex'
import Loader from '../../../components/Loader'

const CyberThreats = () => {
  // const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const { id: serialNumber } = useParams()
  // @ts-ignore
  const { data: gatewayInfo, refetch, isFetching } = useGatewayInfo(serialNumber as any)
  const [filteringDevice, setFilteringDevice] = useState<number | '*'>('*')
  const [callApiLoader, setCallAPILoader] = useState(true)

  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])
  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (reCall) {
      refetch()
      setFilteringDevice(filteringDevice)
      setCallAPILoader(true)
    }
  }, [reCall])
  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  const onDeviceChange = (deviceId: any) => {
    setFilteringDevice(deviceId)
  }

  return (
    <ParentalLayout serialNumber={serialNumber as any} useSidebar='Cyber'>
      <div className=''>
        {isFetching && <Loader />}
        {gatewayInfo && (
          <DevicesList
            email={gatewayInfo?.email}
            onChange={onDeviceChange}
            useMac={gatewayInfo?.wan_mac}
            filteringDevice={filteringDevice}
            setCallAPILoader={setCallAPILoader}
            setActiveDevice={setFilteringDevice}
          />
        )}

        {gatewayInfo && (
          <CyberTable
            email={gatewayInfo.email}
            mac={gatewayInfo.wan_mac}
            filteringDevice={filteringDevice}
            callApiLoader={callApiLoader}
          />
        )}
      </div>
    </ParentalLayout>
  )
}

export default CyberThreats
