import Box from '@mui/material/Box'

import './styles.scss'
import { convertScannedTrafficToReadableUnits, getColorCode } from '../../../../utils/helper'
import SecurityScore from '../../security-score'
import TrafficScanned from '../../traffic-scanned'
import ThreatsBlocked from '../../threats-blocked'
import { useRouterStats } from '../../../../hook/cyber-security/useCyberSecurity'
import { FC, useEffect } from 'react'
import Loader from '../../../Loader/index'
import { Grid } from '@mui/material'
import { useSessionContex } from '../../../../SessionContex'

interface Props {
  email: string
  mac: string
}

const SecurityOverviewCommon: FC<Props> = ({ email, mac }) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const {
    data: routerStats,
    isFetching: RouterStatsFetching,
    refetch: routerStatsRefeching
  } = useRouterStats(email, mac)
  useEffect(() => {
    if (email) {
      routerStatsRefeching()
    }
  }, [])
  useEffect(() => {
    if (reCall) {
      routerStatsRefeching()
    }
  }, [reCall])

  useEffect(() => {
    if (!RouterStatsFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [RouterStatsFetching])

  // console.log('==================', onData)
  // useEffect(() => {
  //   if (onData === 401) {
  //     !openModal && setOpenModal(true)
  //   }
  // }, [onData])
  useEffect(() => {
    if (routerStats === 401) {
      !openModal && setOpenModal(true)
    }
  }, [routerStats])
  // const onFamilyShiel = async (checked: any, title: any) => {
  //   if (title === 'Family Shield') {
  //     if (!checked) {
  //       await OffMutate(email)
  //       setTimeout(() => {
  //         refetchON()
  //       }, 1000)
  //     } else {
  //       notifyError('Enabling access denied')
  //     }
  //   }
  //   if (title === 'Ad Blocker / Phishing Protection') {
  //     if (!checked) {
  //       await adBlockMutate(email)
  //       setTimeout(() => {
  //         refetchON()
  //       }, 1000)
  //     } else {
  //       notifyError('Enabling access denied')
  //     }
  //   }
  //   if (title === 'Safe Search') {
  //     if (!checked) {
  //       await safeSearchMutate(email)
  //       setTimeout(() => {
  //         refetchON()
  //       }, 1000)
  //     } else {
  //       notifyError('Enabling access denied')
  //     }
  //   }
  // }
  return (
    <div style={{ width: '100%' }} className='overflow-y-auto pb-20 security_overview_wrapper'>
      {/* <Box className={'security_overview'}>
        <p className={'title'}>Security Overview</p>

        <div className='grid grid-cols-1 md:grid-cols-4 gap-x-[32px] flex-wrap security_overview_main_block'>
          {!onIsLoading && onData ? (
            <>
              <SecurityCard
                icon={<Shield size={41.66} />}
                title={'Family Shield'}
                activeColor={getColorCode('Info')}
                isChecked={onData?.family_shield}
                onChange={onIsLoading ? () => null : onFamilyShiel}
              />

              <SecurityCard
                icon={<ShieldSecurity size={41.66} />}
                title={'Ad Blocker / Phishing Protection'}
                activeColor={getColorCode('Info')}
                isChecked={onData?.adblock}
                onChange={onIsLoading ? () => null : onFamilyShiel}
              />

              <SecurityCard
                icon={<ShieldSearch size={41.66} />}
                title={'Safe Search'}
                activeColor={getColorCode('Info')}
                isChecked={onData?.safe_search}
                onChange={onIsLoading ? () => null : onFamilyShiel}
              />
            </>
          ) : (
            <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
              Status data not found
            </Box>
          )}
        </div>
        {!!RouterStatsFetching && <Loader />}
      </Box> */}

      <Grid container spacing={2} className='security_overview_inner'>
        {RouterStatsFetching ? (
          <Loader />
        ) : routerStats ? (
          <>
            <Grid item style={{ paddingLeft: '0' }} xs={6} md={4}>
              <SecurityScore
                title={'Security Score'}
                score={routerStats?.stats?.security_score || 0}
                score_suggestions={routerStats?.stats?.score_suggestions_info as any}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TrafficScanned
                trafficScanned={convertScannedTrafficToReadableUnits(routerStats?.stats?.total_traffic_scanned || 0)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <ThreatsBlocked email={email} mac={mac} count={routerStats?.stats} />
            </Grid>
          </>
        ) : (
          <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
            Router Stats data not found
          </Box>
        )}
      </Grid>
    </div>
  )
}

export default SecurityOverviewCommon
