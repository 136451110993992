// import { BreadcrumbContext } from 'src/contexts/breadcrumbData'
import { FC, useContext, useEffect, useState } from 'react'
import ParentalLayout from '../../../../Layouts/parentalLayout/parental-layout'
import { useParams } from 'react-router-dom'
import DevicesList from '../../../../components/parental-components/devices-list/devices-list'
import DeviceDetails from '../../../../components/parental-components/device-details/device-details'
import { ConnectedDevice } from '../../../../models/Xcpem'
import { BreadcrumbContext } from '../../../../contexts/BreadcrumbContext'
import './styles.scss'
import LoadingSpinner from '../../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import { Box } from '@mui/material'
import SessionTimeOutModal from '../../../../components/SessionTimeOutModal'
import { getColorCode } from '../../../../utils/helper'

interface Props {
  serialNumber: string
}

const Policy: FC = () => {
  const { id: serialNumber } = useParams()
  const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)
  const [display, setDisplay] = useState(true)
  const [deviceCall, setDeviceCall] = useState(false)
  const email = localStorage.getItem('email')
  // const gwuid = localStorage.getItem('gwuid')

  const [activeDevice, setActiveDevice] = useState<ConnectedDevice | string>('*')

  const handleActiveDevice = (device: ConnectedDevice) => {
    setActiveDevice(device)
  }

  return (
    <ParentalLayout serialNumber={serialNumber as any} useSidebar='Parental'>
      <div className='policy-main-wrapper'>
        <div>
          {email && (
            <DevicesList
              email={email as any}
              onChange={handleActiveDevice}
              useDeviceInfo={true}
              deviceReCall={deviceCall}
              setDisplay={setDisplay}
              filteringDevice={activeDevice}
              setActiveDevice={setActiveDevice}
            />
          )}
        </div>

        <div className='mb-3 policy-main-block'>
          {display ? (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
              <LoadingSpinner />
            </Box>
          ) : (
            <>
              {activeDevice !== '*' && <DeviceDetails device={activeDevice} setDeviceCall={setDeviceCall} />}

              {activeDevice === '*' && (
                <div className='flex justify-center items-center pt-[20px] policy-block-empty'>
                  <p
                    className='text-[24px] font-sf-semibold policy-block-text'
                    style={{ color: getColorCode('redToBlue') }}
                  >
                    Please select a device to view the device information
                  </p>
                </div>
              )}
            </>
          )}
        </div>

        {/*<div className='flex flex-row flex-wrap w-full gap-x-[22px] gap-y-[32px]'>*/}

        {/*  <BlockedDomains />*/}
        {/*  <BlockCategories />*/}
        {/*  <LimitInternet />*/}
        {/*</div>*/}
      </div>
    </ParentalLayout>
  )
}

export default Policy
