import React, { FC } from 'react'
import Chart from 'react-apexcharts'
import { getColorCode } from '../../../utils/helper'

interface Props {
  score: number
}

const RadialBarChart: FC<Props> = ({ score }) => {
  var options = {
    series: [(score / 5) * 100],
    chart: {
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: '50%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.14
          }
        },
        track: {
          background: '#EEEEEE',
          strokeWidth: '100%',
          margin: 0 // margin is in pixels
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: 10,
            show: true,
            color: '#000000',
            fontSize: '25px',
            fontWeight: 700,
            fontFamily: 'Roboto'
          },
          value: {
            formatter: function (val: any) {
              return parseInt(val)
            },
            color: '#000000',
            fontSize: '25px',
            fontWeight: 700,
            fontFamily: 'Roboto',
            show: false,
            position: 'absolute',
            marginBottom: '70px'
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [getColorCode('orangeHitBlue')],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: [`${score?.toFixed(1)}/5.0`],
    colors: [getColorCode('redToBlue')]
  }
  const series = [(score / 5) * 100]

  return <Chart options={options as any} series={series} type='radialBar' height={250} />
}

export default RadialBarChart
