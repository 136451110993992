import TimeSupervisionChartStaging from './staging/time-supervision-chart-staging'
import TimeSupervisionChartCommon from './common/time-supervision-chartCommon'

const TimeSupervisionChart: React.FC<any> = ({ data }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <TimeSupervisionChartStaging data={data} />
      ) : (
        <TimeSupervisionChartCommon data={data} />
      )}
    </>
  )
}

export default TimeSupervisionChart
