import { Close } from '@mui/icons-material'
import { Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { getColorCode } from '../../utils/helper'

interface VersionCheckModelProps {
  isOpen: boolean
  setShowVerifyVersionOpen: (value: boolean) => void
}

const VersionCheckModel: React.FC<VersionCheckModelProps> = ({ isOpen, setShowVerifyVersionOpen }) => {
  const navigate = useNavigate()
  const { logout } = useAuth()
  const handleOkClick = () => {
    if (window.location.pathname === '/login') {
      logout()
      navigate('/login')
    }
    setShowVerifyVersionOpen(false)
    window.location.reload()
  }

  return (
    <>
      <Modal open={isOpen} footer={null} closable={false} width={500} wrapClassName={'token_expired'} centered={true}>
        <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}>
            Web App Update
          </h3>
          <Close
            className='cursor-pointer'
            onClick={() => {
              handleOkClick()
            }}
          />
        </div>
        <div className='text-center mb-4'>
          <h3 className='conformation_text text-lg font-semibold'>The web application is not the latest version.</h3>
          <p className='text-lg font-semibold'>Please update by clicking “OK”.</p>
        </div>
        <div className='d-flex justify-center items-center'>
          <button
            type='button'
            className='ok_btn'
            style={{
              textTransform: 'uppercase',
              color: getColorCode('logOutBtnText'),
              background: getColorCode('logOutBtnBg'),
              border: `2px solid ${getColorCode('logOutBtnText')}`,
              fontWeight: 'bold'
            }}
            data-testid='testid_logout_button2'
            onClick={() => {
              handleOkClick()
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
    </>
  )
}

export default VersionCheckModel
