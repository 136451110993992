import { Spin } from 'antd'
import React from 'react'

const Loader = () => {
  return (
    <div
      className={`loading_wrapper  loading-${
        process.env.REACT_APP_COLOR === 'hitron'
          ? 'hitron'
          : process.env.REACT_APP_COLOR === 'customer'
          ? 'customer'
          : 'local'
      }-wrapper`}
    >
      <Spin
        tip='Loading'
        style={{
          color:
            process.env.REACT_APP_COLOR === 'hitron'
              ? '#004C97'
              : process.env.REACT_APP_COLOR === 'customer'
              ? '#ff974c'
              : '#FF8181'
        }}
      >
        <div className='content' />
      </Spin>
    </div>
  )
}

export default Loader
