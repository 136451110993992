import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import { CustomTableRowProps } from './model'

export const CustomTable = styled(Table)(({ theme }) => ({
  '& .MuiTableRow-root:not(:last-child)': {
    marginBottom: '15px' // Add margin bottom to create gap between rows
  },
  borderCollapse: 'separate',
  borderSpacing: '0px 16px',
  // overflow: 'auto',
  // display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'auto hidden',

  '& tbody tr td': {
    borderBottom: '2px solid transparent',
    borderTop: '2px solid transparent'
  },
  '& tbody tr td:last-child': {
    borderRight: '2px solid transparent'
  },

  '& tbody tr:hover td': {
    borderBottom: '2px solid #CBCFDE',
    borderTop: '2px solid #CBCFDE',
    cursor: 'pointer'
  },

  '& tbody tr:hover td:last-child': {
    borderRight: '2px solid #CBCFDE'
  }
}))

export const CustomTableHeaderRow = styled(TableRow)(({ theme }) => ({
  '& > th': {
    color: '#282828',
    fontStyle: 'normal',
    fontSize: '18px!important',
    lineHeight: '21px',
    fontFamily: 'SFProDisplay-Regular, sans-serif',
    borderBottom: 'none'
  }
}))

export const CustomTableRow = styled(TableRow)<CustomTableRowProps>(({ styledcolor }) => ({
  '& > :first-of-type': {
    borderTopLeftRadius: `10px`,
    borderBottomLeftRadius: `10px`,
    borderLeft: `4px solid ${styledcolor ? styledcolor : '#FFFFFF'}`
  },

  '& > :last-child': {
    borderTopRightRadius: `10px`,
    borderBottomRightRadius: `10px`
  },
  '& thead tr': {
    borderBottom: 'none',
    BorderTop: '2px solid red'
  },
  '& > th': {
    borderBottom: 'none'
  },
  '& > td': {
    borderBottom: 'none'
  },

  background: 'white',
  marginBottom: '20px !important',
  height: '60px'
}))
