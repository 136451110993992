import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

export const useCellularUsage = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `/rpc/gateway_cellular_usage_summary?_gw_uid=${gwuid}&_timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data[0]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Summary: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularUsageSummary = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage-summary'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `/rpc/gateway_cellular_usage_summary?_gw_uid=${gwuid}&_timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data[0]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Summary: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useCellularUsageData = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage-data'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `/rpc/gateway_cellular_usage_data?_gw_uid=${gwuid}&_timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage Data: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useCellularUsageGraph = (
  activeGw_Uid: string,
  timestamp: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: any
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cellular-usage-graph'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      const endpoint = `/rpc/gateway_cellular_usage_graph?_gw_uid=${gwuid}&_timestamp_from=${timestamp}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cellular Usage graph: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
