import moment from 'moment'
import { useGatewayAdminLogs, useGatewayAllAdminLogs } from '../../../../hook/useGatewayLogs'
import Loader from '../../../../components/Loader'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import TablePagination from '@mui/material/TablePagination'
import { useEffect, useState, ChangeEvent } from 'react'
import { useSessionContex } from '../../../../SessionContex'
import { Tooltip } from 'antd'
// import moment from 'moment-timezone'

const GatewayAdminActions = () => {
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data, isLoading, refetch, isFetching } = useGatewayAdminLogs(offset, gw_uid, limit)
  const { data: allAdminData, isLoading: allIsLoading, refetch: allDataRefech } = useGatewayAllAdminLogs(gw_uid)

  // const [timestamp] = useState('2024-02-14T12:25:17.732499')
  // const [foundTimeZone, setFoundTimeZone] = useState(null)

  // useEffect(() => {
  //   // Iterate over all time zones
  //   const timeZones = moment.tz.names()
  //   let timeZoneFound: any = null

  //   for (const timeZone of timeZones) {
  //     if (moment.tz(timestamp, timeZone).isValid()) {
  //       timeZoneFound = timeZone
  //       break
  //     }
  //   }
  //   console.log(timeZone, '============@@', timeZoneFound)

  //   setFoundTimeZone(timeZoneFound)
  // }, [timestamp])
  useEffect(() => {
    refetch()
  }, [offset, limit, currentPage])

  useEffect(() => {
    allDataRefech()
  }, [])

  useEffect(() => {
    if (allAdminData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [allAdminData])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  useEffect(() => {
    allDataRefech()
    refetch()
    setOffset(0)
    setCurrentPage(0)
  }, [gw_uid])
  useEffect(() => {
    if (reCall) {
      if (currentPage !== 0 || offset !== 0) {
        setCurrentPage(0)
        setOffset(0)
      } else {
        refetch()
      }
      allDataRefech()
    }
  }, [reCall])

  useEffect(() => {
    if (!(isLoading || isFetching)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isLoading, isFetching])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setOffset(0)
    setCurrentPage(0)
  }

  return (
    <div>
      {isLoading || isFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <table className='pc-table w-full'>
          <thead>
            <tr>
              <td>Gateway UID</td>
              <td>Email</td>
              <td>Event</td>
              <td>Time</td>
            </tr>
          </thead>
          <tbody>
            {!!data && data?.length > 0 ? (
              data?.map((item: any, index: any) => {
                return (
                  <tr key={`admin_logs${index}`}>
                    <td>{item?.gw_uid || '-'}</td>
                    <td>{item?.email || '-'}</td>
                    <td>{item?.event?.replace('Admin', '') || '-'}</td>
                    <td className='cursor-pointer'>
                      <Tooltip
                        color='#707e8c'
                        placement='top'
                        title={
                          moment.utc(item?.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                          // moment
                          // .tz(moment.utc(item?.timestamp), timeZone)
                          // .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                        }
                      >
                        <> {moment.utc(item?.timestamp).local().fromNow() || '-'}</>
                      </Tooltip>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr className='empty-row'>
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  Admin logs not found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {!isLoading && !isFetching && data && data?.length > 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={allAdminData?.length || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
export default GatewayAdminActions
