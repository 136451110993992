import { ReactNode, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import QuickSwitchToolTip from '../../shadcn/components/ui/radix-tooltip'
import VerifyPin from '../../components/parental-components/verify-pin/verify-pin'
import './styles.scss'

type ParentalLayoutProps = {
  children: ReactNode
  bg?: string
  serialNumber: string
  useSidebar: 'Parental' | 'Cyber' | 'Home'
}

const ParentalLayout = ({ children, bg, serialNumber, useSidebar }: ParentalLayoutProps) => {
  const activeAlerts = localStorage.getItem('assignedAlerts')

  const navigate = useNavigate()

  const location = useLocation()

  const isNotificationsActive = location.pathname === '/notifications'

  const [pcVerified, setPcVerified] = useState<boolean>(false)
  const [open, setOpen] = useState(true)

  const isPCverified = localStorage.getItem('isPCverified') === 'true' || pcVerified

  const handleOnPCVerified = () => {
    setPcVerified(true)
  }

  const toggleDrawer = (e: any) => {
    e.preventDefault()
    setOpen(!open)
  }
  return (
    <div className='flex overflow-hide layout-wrapper'>
      <div className={`grow layout-wrapper-inner`}>
        {/* <div className={`grow layout-wrapper-inner`}></div> */}
        <div className='flex layout-inner-main'>
          <div className='w-full overflow-x-auto layout-inner-block'>
            <div className='py-[20px] page-content '>
              {isPCverified ? children : <VerifyPin onPcVerified={handleOnPCVerified} />}
            </div>
          </div>
          {activeAlerts !== null && (
            <div className='w-[62px] quick-switch-navbar bg-white flex flex-col items-center pt-[34px] gap-y-[6px] layout-inner-mainblock'>
              {activeAlerts !== null
                ? JSON.parse(activeAlerts).map((alert: any, index: number) => {
                    return (
                      <QuickSwitchToolTip
                        key={`active_alert_${index}`}
                        email={alert.user}
                        serialNumber={alert.serialNumber}
                        alert={alert.alert}
                      />
                    )
                  })
                : null}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ParentalLayout
