import React from 'react'

const AlertsIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_129_6964" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
        <path d="M0 0H22V22H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_129_6964)">
        <path d="M20.0234 21.3555H1.97656" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.55469 21.3555V18.7773H17.4453V21.3555" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.6445 14.8672C11.6445 15.2231 11.356 15.5117 11 15.5117C10.644 15.5117 10.3555 15.2231 10.3555 14.8672C10.3555 14.5112 10.644 14.2227 11 14.2227C11.356 14.2227 11.6445 14.5112 11.6445 14.8672Z" fill="black" />
        <path d="M11 9.71094V12.2891" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0.644531 11H1.97656" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.0234 11H21.3555" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 1.97656V0.644531" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.6195 4.61914L3.70801 3.70764" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.2921 3.70764L17.3806 4.61914" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.84375 18.7773V11C5.84375 8.15229 8.15229 5.84375 11 5.84375C13.8477 5.84375 16.1563 8.15229 16.1563 11V18.7773" stroke="black" strokeWidth="1.28906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
export default AlertsIcon
