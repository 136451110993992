import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router'
import CustomersActivities from './Activity'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import CustometDeviceStatus from './custometDeviceStatus'
import CustomerDeviceInfo from './Informations'
import SystemOverviewDetails from './System Overview'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useSessionContex } from '../../../SessionContex'

const CustomersOverview = ({ mqttRes, callMqtt, sendMqttMessage }: any) => {
  // const location = useLocation()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const [wanMqtt, setWanMqtt] = useState(true)

  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const { data: gatewayInfo, isFetching: gatewayFeaching, refetch: gatewayRefetch }: any = useGatewayInfo(activeGw_Uid) //User device data

  useEffect(() => {
    if (reCall) {
      gatewayRefetch()
      setWanMqtt(true)

      setTimeout(() => {
        setReCall(false)
      }, 500)
    }
  }, [reCall])

  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
    if (gatewayInfo) {
      setWanMqtt(true)
    }
  }, [gatewayInfo])

  useEffect(() => {
    // if (activeGw_Uid && !!location.pathname && location.pathname.split('/')[2] !== activeGw_Uid) {
    setWanMqtt(false)

    if (activeGw_Uid) {
      gatewayRefetch()
    }
  }, [activeGw_Uid])

  return (
    <div className='d-flex '>
      <CustometDeviceStatus
        gatewayInfo={gatewayInfo}
        gatewayFeaching={gatewayFeaching}
        wanMqtt={wanMqtt}
        setWanMqtt={setWanMqtt}
        mqttRes={mqttRes}
      />
      <SystemOverviewDetails gatewayInfo={gatewayInfo} gatewayFeaching={gatewayFeaching} />
      <div className='right_parts'>
        <CustomerDeviceInfo
          customerinfo={gatewayInfo}
          wanMqtt={wanMqtt}
          setWanMqtt={setWanMqtt}
          gatewayFeaching={gatewayFeaching}
          mqttRes={mqttRes}
          callMqtt={callMqtt}
          sendMqttMessage={sendMqttMessage}
        />
        <div className='mt-3 w-full'>
          <CustomersActivities />
        </div>
      </div>
    </div>
  )
}

export default CustomersOverview
