import { Box, Skeleton, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import React from 'react'
import CountUp from 'react-countup'
import CardComponent from '../../../components/Cards/general-card/CardComponent'
import { getColorCode } from '../../../utils/helper'
import styles from './styles.module.scss'

interface RoundedBarChartProps {
  data?: number
  title: string
  isLoading?: boolean
  style?: any
  className?: string
  Logo?: any
  subData?: any
  testId?: any
}

const WeeklyRoundedBarChart: React.FC<RoundedBarChartProps> = ({
  data,
  title,
  isLoading,
  style,
  className,
  Logo,
  subData,
  testId
}: any) => {
  return (
    <CardComponent
      className={`Alerts-Summary-Card Alerts-Summary-Card-${className}`}
      styles={{ ...style }}
      testId={testId}
      body={
        <div className={styles.customer_stats_card}>
          <Stack direction='column' justifyContent={'center'}>
            <h2 className={styles.card_title} style={{ marginBottom: title === 'Reports' ? '0px' : '24px' }}>
              {title}
            </h2>
            {isLoading && title !== 'Reports' ? (
              <Skeleton variant='rounded' width={'50px'} height={50} />
            ) : (
              <>
                <h1
                  className={styles.card_value}
                  style={{
                    color: title === 'Data Usage' ? '#2C2FEF' : '',
                    display: title === 'Reports' && !data ? 'none' : 'block'
                  }}
                >
                  <CountUp start={0} end={data} duration={2} />
                  {title === 'Data Usage' && (
                    <>
                      <div className='breadcrumbs-line' style={{ height: '45px', backgroundColor: '#000' }} />
                      <Box>
                        <Typography sx={{ color: '#00000080', fontSize: '16px', fontWeight: 500 }}>
                          in last 24 hours
                        </Typography>
                        <Typography sx={{ color: '#00000080', fontSize: '16px', fontWeight: 500 }}>
                          by 322 devices
                        </Typography>
                      </Box>
                    </>
                  )}
                </h1>
              </>
            )}
          </Stack>
          <Stack direction='row' marginLeft={'30px'} alignItems={'center'}>
            {subData && (
              <Stack direction='column' marginLeft={4}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  sx={{
                    border: `2px solid ${
                      !subData?.online_devices_lte_count || subData?.online_devices_lte_count === 0
                        ? getColorCode('grayToGray')
                        : getColorCode('greenToGreen')
                    }`,
                    borderRadius: '4px',
                    margin: '3px',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    className='font-sf-regular capitalize'
                    style={{
                      padding: '2px 10px'
                    }}
                  >
                    LTE
                  </div>
                  {isLoading ? (
                    <Skeleton variant='rounded' width={'50px'} height={28} />
                  ) : (
                    <div
                      style={{
                        background: `${
                          !subData?.online_devices_lte_count || subData?.online_devices_lte_count === 0
                            ? getColorCode('grayToGray')
                            : getColorCode('greenToGreen')
                        }`,
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <CountUp start={0} end={subData?.online_devices_lte_count} duration={2} />
                    </div>
                  )}
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={1}
                  sx={{
                    border: `2px solid ${
                      !subData?.online_devices_ethernet_count || subData?.online_devices_ethernet_count === 0
                        ? getColorCode('grayToGray')
                        : getColorCode('greenToGreen')
                    }`,
                    borderRadius: '4px',
                    margin: '3px',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    className='font-sf-regular capitalize'
                    style={{
                      padding: '2px 10px'
                    }}
                  >
                    ETH
                  </div>
                  {isLoading ? (
                    <Skeleton variant='rounded' width={'50px'} height={28} />
                  ) : (
                    <div
                      style={{
                        background: `${
                          !subData?.online_devices_ethernet_count || subData?.online_devices_ethernet_count === 0
                            ? getColorCode('grayToGray')
                            : getColorCode('greenToGreen')
                        }`,
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <CountUp start={0} end={subData?.online_devices_ethernet_count} duration={2} />
                    </div>
                  )}
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={1}
                  sx={{
                    border: `2px solid ${
                      !subData?.online_devices_ethernet_lte_count || subData?.online_devices_ethernet_lte_count === 0
                        ? getColorCode('grayToGray')
                        : getColorCode('greenToGreen')
                    }`,
                    borderRadius: '4px',
                    margin: '3px',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    className='font-sf-regular capitalize'
                    style={{
                      padding: '2px 10px'
                    }}
                  >
                    ETH (5G)
                  </div>
                  {isLoading ? (
                    <Skeleton variant='rounded' width={'50px'} height={28} />
                  ) : (
                    <div
                      style={{
                        background: `${
                          !subData?.online_devices_ethernet_lte_count ||
                          subData?.online_devices_ethernet_lte_count === 0
                            ? getColorCode('grayToGray')
                            : getColorCode('greenToGreen')
                        }`,
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <CountUp start={0} end={subData?.online_devices_ethernet_lte_count} duration={2} />
                    </div>
                  )}
                </Stack>

                {/* {Object.entries(subData).map(([key, value]: any) => (
                  <Stack
                    key={key}
                    direction={'row'}
                    spacing={1}
                    sx={{
                      border: `2px solid ${getColorCode('greenToGreen')}`,
                      borderRadius: '4px',
                      margin: '2px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      className='font-sf-regular capitalize'
                      style={{
                        padding: '2px 10px'
                      }}
                    >
                      {key}
                    </div>
                    {isLoading ? (
                      <Skeleton variant='rounded' width={'50px'} height={17} />
                    ) : (
                      <div
                        style={{
                          background: getColorCode('greenToGreen'),
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <CountUp start={0} end={value} duration={2} />
                      </div>
                    )}
                  </Stack>
                ))} */}
              </Stack>
            )}
            <Stack direction='column'>
              <span className={process.env.REACT_APP_MODE === 'hitron' ? styles.hitron_logo : styles.logo}>{Logo}</span>
            </Stack>
          </Stack>
        </div>
      }
    />
  )
}

export default WeeklyRoundedBarChart
