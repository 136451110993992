import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { apiUrl } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

export const useWifiQoE = ({
  dev_mesh_role,
  parent_gw_uid,
  wan_mac,
  gw_uid
}: any): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['use-Wifi-QoE'],
    async () => {
      if (!!dev_mesh_role) {
        try {
          const response = await xcpemSec.get<any[]>(
            dev_mesh_role === 'CAP'
              ? `${apiUrl}/rpc/webapp_wifi_qoe?_gw_uid=${gw_uid}&_mesh_role=${dev_mesh_role}`
              : `${apiUrl}/rpc/webapp_wifi_qoe?_gw_uid=${parent_gw_uid}&_mesh_role=${dev_mesh_role}&_mac_satellite=${wan_mac}`
          )
          return response.data
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Wifi Coverage: ${error?.response?.data?.message || error?.message}`)
            return error
          }
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['use-Wifi-QoE'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
