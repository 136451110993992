// import ThreatsTile from '../../cyber-security/threats-tile/threats-tile'
// import { FC, useEffect } from 'react'
// import { useParentalConnectedDevices } from '../../../hook/parental-control'
// // import { currentISOTime } from '../../../utils/helper'
// import './styles.scss'
// import moment from 'moment'
// import { Box, CircularProgress, Skeleton } from '@mui/material'
// import SessionTimeOutModal from '../../SessionTimeOutModal'
// import { useSessionContex } from '../../../SessionContex'
// import { getColorCode } from '../../../utils/helper'
// import Loader from '../../Loader'
// import { Spin } from 'antd'

// interface Props {
//   email: string
//   onChange: (deviceId: any) => void
//   useMac?: boolean
//   useDeviceInfo?: boolean
//   deviceReCall?: boolean
//   setDisplay?: any
//   filteringDevice?: any
//   setCallAPILoader?: any
//   setActiveDevice?: any
// }
// const DevicesList: FC<Props> = ({
//   email,
//   onChange,
//   useMac,
//   useDeviceInfo,
//   deviceReCall = false,
//   setDisplay,
//   filteringDevice,
//   setCallAPILoader,
//   setActiveDevice
// }) => {
//   // const time = currentISOTime()
//   const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
//   const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

//   const time = moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss')
//   // const time = moment.tz(moment.utc().subtract(7, 'days'), timeZone).toISOString()
//   const { data, refetch, isFetching, error } = useParentalConnectedDevices(time, email)

//   useEffect(() => {
//     refetch()
//   }, [])
//   useEffect(() => {
//     if (reCall) {
//       refetch()
//     }
//   }, [reCall])
//   useEffect(() => {
//     if (data === 401) {
//       !openModal && setOpenModal(true)
//     }

//     if (!!data) {
//       if (data?.length !== 0 && setActiveDevice) {
//         const newObj: any =
//           data &&
//           data.filter(
//             (obj: any) =>
//               obj?.device_mac === (typeof filteringDevice === 'object' ? filteringDevice.device_mac : filteringDevice)
//           )

//         if (newObj && newObj?.length !== 0) {
//           setActiveDevice(
//             filteringDevice === '*' ? '*' : typeof filteringDevice === 'object' ? newObj[0] : newObj[0]?.device_mac
//           )
//         } else {
//           setActiveDevice('*')
//         }
//       }
//     }
//   }, [data])

//   useEffect(() => {
//     refetch()
//   }, [email])

//   useEffect(() => {
//     if (deviceReCall) {
//       refetch()
//     }
//   }, [deviceReCall])

//   useEffect(() => {
//     if (setDisplay) {
//       setDisplay(isFetching)
//     }
//     if (setCallAPILoader && data !== undefined) {
//       setCallAPILoader(isFetching)
//     }

//     if (!isFetching) {
//       if (reCall) {
//         setTimeout(() => {
//           setReCall(false)
//         }, 500)
//       }
//     }
//   }, [isFetching])

//   return (
//     <>
//       <div
//         className={`flex ${
//           isFetching ? 'w-100' : 'gap-x-[30px]'
//         } overflow-x-auto flex-row mb-[28px] pb-3 deviceList-main-wrapper`}
//       >
//         {error !== null && (!data || (data && data?.length === 0)) && (
//           <Box
//             marginLeft={2.5}
//             paddingBottom={4}
//             color={getColorCode('redToBlue')}
//             justifyContent={'center'}
//             display={'flex'}
//             height={'132px'}
//           >
//             Device data not found
//           </Box>
//         )}
//         {isFetching ? (
//           <Box
//             display={'flex'}
//             justifyContent={'center'}
//             alignItems={'center'}
//             width={'100%'}
//             height={'132px'}
//             margin={'auto'}
//           >
//             <CircularProgress
//               size={20}
//               sx={{
//                 color:
//                   process.env.REACT_APP_COLOR === 'hitron'
//                     ? '#004C97'
//                     : process.env.REACT_APP_COLOR === 'customer'
//                     ? '#ff974c'
//                     : '#FF8181'
//               }}
//             />
//           </Box>
//         ) : (
//           data && (
//             <ThreatsTile
//               devices={data}
//               onChange={onChange}
//               useMac={useMac}
//               useDeviceInfo={useDeviceInfo}
//               reCall={reCall}
//               filteringDevice={filteringDevice}
//             />
//           )
//         )}
//       </div>

//       <div className='hidden'>
//         <table className={'pc-table'}>
//           <thead>
//             <tr>
//               <td>isonline</td>
//               <td>IP</td>
//               <td>mac</td>
//               <td>name</td>
//               <td>type</td>
//               <td>fingerbank name</td>
//               <td>manufacturer name</td>
//               <td>os name</td>
//               <td>os parent name</td>
//               <td>parent_device_type_hierarchy</td>
//             </tr>
//           </thead>

//           <tbody>
//             {data &&
//               data?.length > 0 &&
//               data?.map((item: any, index: any) => {
//                 return (
//                   <tr key={`device_${index}`}>
//                     <td>{item.is_active}</td>
//                     <td>{item.device_ip}</td>

//                     <td>{item.device_macid}</td>

//                     <td>{item.device_name}</td>

//                     <td>{item.device_type}</td>

//                     <td>{item.fingerbank_device_name}</td>
//                     <td>{item.manufacturer_name}</td>
//                     <td>{item.os_name}</td>
//                     <td>{item.os_parent_name}</td>

//                     <td>{item.parent_device_type_hierarchy?.join(',')}</td>
//                   </tr>
//                 )
//               })}
//           </tbody>
//         </table>
//       </div>
//     </>
//   )
// }
// export default DevicesList
import DevicesListStaging from '../staging/devices-list/devices-list'
import DevicesListCommon from '../common/devices-list/devices-list'
const DevicesList: React.FC<any> = ({
  email,
  onChange,
  useMac,
  useDeviceInfo,
  deviceReCall = false,
  setDisplay,
  filteringDevice,
  setCallAPILoader,
  setActiveDevice
}) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <DevicesListStaging
          email={email}
          onChange={onChange}
          useMac={useMac}
          useDeviceInfo={useDeviceInfo}
          deviceReCall={deviceReCall}
          setDisplay={setDisplay}
          filteringDevice={filteringDevice}
          setCallAPILoader={setCallAPILoader}
          setActiveDevice={setActiveDevice}
        />
      ) : (
        <DevicesListCommon
          email={email}
          onChange={onChange}
          useMac={useMac}
          useDeviceInfo={useDeviceInfo}
          deviceReCall={deviceReCall}
          setDisplay={setDisplay}
          filteringDevice={filteringDevice}
          setCallAPILoader={setCallAPILoader}
          setActiveDevice={setActiveDevice}
        />
      )}
    </>
  )
}

export default DevicesList
