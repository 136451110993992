// import React, { useEffect, useState } from 'react'
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
// import { useSocialSupervisionTotal } from '../../../hook/parental-control'
// import { getColorCode } from '../../../utils/helper'
// import { COLORS } from '../../../utils/colors'
// import LoadingSpinner from '../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
// import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
// import dayjs from 'dayjs'
// // import {
// //   DropdownMenu,
// //   DropdownMenuContent,
// //   DropdownMenuItem,
// //   DropdownMenuTrigger
// // } from '../../../Layouts/AnimatedLayout/DropDown/dropdown-menu'
// // import DownSideArrow from '../../../common-images/app-logo/downside-arrow'
// import moment from 'moment'
// import { useSessionContex } from '../../../SessionContex'
// import { COLORSHITRON } from '../../../utils/colorsHitron'

// interface SocialPieChartProps {
//   email: string
//   deviceFilter?: any
//   dataFilter?: any
//   fromDate?: any
//   isFilter?: boolean
// }

// const SocialPieChart: React.FC<SocialPieChartProps> = ({ isFilter, email, deviceFilter, dataFilter, fromDate }) => {
//   const [filterLabel, setFilterLabel] = useState<string>('today')
//   const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
//   const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
//   // const [hours] = useState<number>(dayjs().tz(timeZone).diff(dayjs().tz(timeZone).startOf('day'), 'hour'))

//   const [fromDates, setFromDates] = useState<any>(
//     moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss')
//   )
//   const {
//     data: socialSupervision,
//     // isLoading,
//     isFetching,
//     error,
//     refetch
//   } = useSocialSupervisionTotal(fromDate ? fromDate : fromDates, email, deviceFilter)
//   useEffect(() => {
//     if (socialSupervision === 401) {
//       !openModal && setOpenModal(true)
//     }
//   }, [socialSupervision])
//   useEffect(() => {
//     refetch()
//   }, [deviceFilter, dataFilter, fromDate, fromDates])

//   useEffect(() => {
//     refetch()
//   }, [])
//   useEffect(() => {
//     if (reCall) {
//       refetch()
//     }
//   }, [reCall])
//   useEffect(() => {
//     if (!isFetching) {
//       if (reCall) {
//         setTimeout(() => {
//           setReCall(false)
//         }, 500)
//       }
//     }
//   }, [isFetching])

//   const data =
//     socialSupervision &&
//     socialSupervision?.social_networks_list &&
//     socialSupervision?.social_networks_list?.length > 0 &&
//     socialSupervision?.social_networks_list?.map((item: any) => {
//       return {
//         name: item.name,
//         y: parseFloat(item.pct.toFixed(2))
//       }
//     })

//   const customColors =
//     process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer' ? COLORSHITRON : COLORS

//   const options: Highcharts.Options = {
//     chart: {
//       type: 'pie'
//     },
//     title: {
//       text: ''
//     },
//     plotOptions: {
//       pie: {
//         allowPointSelect: true,
//         cursor: 'pointer',
//         dataLabels: {
//           enabled: false,
//           format: '<b>{point.name}</b>: {point.percentage:.1f}%'
//         },
//         colors: customColors,
//         showInLegend: true
//       }
//     },
//     legend: {
//       align: 'center',
//       verticalAlign: 'bottom',
//       layout: 'horizontal',
//       itemMarginTop: 10,
//       itemMarginBottom: 10,
//       itemStyle: {
//         fontSize: '18px',
//         fontFamily: 'Arial, sans-serif'
//       },
//       padding: 30
//     },
//     tooltip: {
//       formatter: function () {
//         return `<b>${this.point.name}</b>: ${this.point.y}%`
//       }
//     },
//     series: [
//       {
//         name: 'share',
//         data: data,
//         type: 'pie'
//       }
//     ],
//     credits: {
//       enabled: false
//     }
//   }

//   const CartData = [
//     {
//       name: 'Today',
//       value: 'today'
//     },
//     {
//       name: 'Last 7 Days',
//       value: 'thisWeek'
//     },
//     {
//       name: 'Last 30 Days',
//       value: 'thisMonth'
//     }
//   ]

//   const handleFilterChange = (e: any) => {
//     if (e.target.value === 'thisMonth') {
//       setFromDates(moment.utc(moment.tz(moment().subtract(1, 'month'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
//       // setFromDates(dayjs.tz(dayjs.utc().subtract(730, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss'))
//     } else if (e.target.value === 'thisWeek') {
//       setFromDates(moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
//       // setFromDates(dayjs.tz(dayjs.utc().subtract(168, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss'))
//     } else if (e.target.value === 'today') {
//       setFromDates(moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
//       // setFromDates(dayjs.tz(dayjs.utc().subtract(22, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss'))
//     }
//     setFilterLabel(e.target.value)
//   }

//   return (
//     <div className='w-full bg-white rounded-[10px] text-center'>
//       <div
//         className={` w-full ${
//           isFilter ? 'inline-flex justify-between items-center' : 'text-center'
//         } time-supervision-inner-wrapper p-2`}
//       >
//         {isFilter && (
//           <span className={`text-[24px] font-sf-bold time-supervision-title pt-2 pl-2`}>Social Supervision</span>
//         )}

//         {isFilter && (
//           <div className='w-40 mt-2 text-left'>
//             <FormControl fullWidth>
//               <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
//               <Select
//                 labelId='demo-simple-select-label'
//                 id='demo-simple-select'
//                 value={filterLabel}
//                 label='Filter'
//                 onChange={handleFilterChange}
//                 className='text-uppercase'
//               >
//                 {CartData.map((option: any, index: number) => {
//                   return (
//                     <MenuItem value={option.value} className='text-uppercase' key={`option_${index}_${option}`}>
//                       {option.name}
//                     </MenuItem>
//                   )
//                 })}
//               </Select>
//             </FormControl>
//           </div>
//           // <DropdownMenu>
//           //   <DropdownMenuTrigger className='dropdown-btn flex'>
//           //     {filterLabel}
//           //     <span className='down-arrow'>
//           //       <DownSideArrow />
//           //     </span>
//           //   </DropdownMenuTrigger>
//           //   <DropdownMenuContent className='drop-down'>
//           //     {CartData.map((option: any, index: number) => {
//           //       return (
//           //         <DropdownMenuItem
//           //           className='drop-down-option'
//           //           key={`option_${index}_${option}`}
//           //           onClick={(e: any) => handleFilterChange(option)}
//           //         >
//           //           {option.name}
//           //         </DropdownMenuItem>
//           //       )
//           //     })}
//           //   </DropdownMenuContent>
//           // </DropdownMenu>
//         )}
//       </div>
//       {isFetching ? (
//         <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'200px'}>
//           <LoadingSpinner />
//         </Box>
//       ) : socialSupervision?.social_networks_list === null ||
//         (socialSupervision && socialSupervision?.social_networks_list?.length === 0) ||
//         error !== null ? (
//         <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
//           Social data not found
//         </Box>
//       ) : (
//         <HighchartsReact highcharts={Highcharts} options={options} />
//       )}
//     </div>
//   )
// }

// export default SocialPieChart
import SocialPieChartStaging from '../staging/charts/social-supervision-pie-chart'
import SocialPieChartCommon from '../common/charts/social-supervision-pie-chart'
const SocialPieChart: React.FC<any> = ({ isFilter, email, deviceFilter, dataFilter, fromDate }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <SocialPieChartStaging
          isFilter={isFilter}
          email={email}
          deviceFilter={deviceFilter}
          dataFilter={dataFilter}
          fromDate={fromDate}
        />
      ) : (
        <SocialPieChartCommon
          isFilter={isFilter}
          email={email}
          deviceFilter={deviceFilter}
          dataFilter={dataFilter}
          fromDate={fromDate}
        />
      )}
    </>
  )
}

export default SocialPieChart
