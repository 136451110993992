import SecurityOverviewCommon from '../common/security-overview'
import SecurityOverviewStaging from '../staging/security-overview'

const ParentalControlTable: React.FC<any> = ({ email, mac, gwuid }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <SecurityOverviewStaging email={email} mac={mac} gwuid={gwuid} />
      ) : (
        <SecurityOverviewCommon email={email} mac={mac} />
      )}
    </>
  )
}

export default ParentalControlTable
