import { NetworkHealthMapState, NetworkHealthMapAction } from './types'

const initialState: NetworkHealthMapState = {
  data: undefined,
  deviceData: [],
  loading: false,
  error: null
}

const networkHealthMapReducer = (state = initialState, action: NetworkHealthMapAction): NetworkHealthMapState => {
  switch (action.type) {
    case 'GET_NETWORK_MAP_DEVICES_REQUEST':
      return {
        ...state,
        data: undefined,
        loading: true,
        error: null
      }
    case 'GET_NETWORK_MAP_DEVICES_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null
      }

    case 'GET_NETWORK_MAP_DEVICES_FAILURE':
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error
      }
    case 'GET_NETWORK_DEVICES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'GET_NETWORK_DEVICES_SUCCESS':
      return {
        ...state,
        deviceData: action.payload,
        loading: false,
        error: null
      }
    default:
      return state
  }
}

export default networkHealthMapReducer
