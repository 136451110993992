import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'
import { RootState } from './types'
import { KEYCLOAK_CONFIG, apiUrl } from '../../app-config'
import { notifyError } from '../../components/Toaster'
import { useState } from 'react'
import xcpemSec from '../../axiosInstances/xcpemSec'

interface RangeFilterProps {
  filter: string
  offset: number
  limit: number
}

export const getDeviceStatistics = ({
  filter,
  limit,
  offset
}: RangeFilterProps): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const basUrl = `/webapp_dashboard_all_devices?offset=${offset}&limit=${limit}${filter}`
    try {
      dispatch({ type: 'GET_DEVICE_STATISTICS_REQUEST' }) // Set table loading to true
      const response = await xcpemSec.get(`${basUrl}`)
      const deviceStatistics = response.data
      dispatch({ type: 'GET_DEVICE_STATISTICS_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status

        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
      } else {
        notifyError(`Device Statistics: ${error.message}`)
        dispatch({ type: 'GET_DEVICE_STATISTICS_FAILURE', error: error.message })
      }
    }
  }
}

export const getDeviceFilterStatistics = ({
  filter,
  limit,
  offset
}: RangeFilterProps): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const basUrl = `/webapp_dashboard_all_devices?offset=${offset}&limit=${limit}${filter}`
    try {
      dispatch({ type: 'GET_DEVICE_STATISTICS_FILTER_REQUEST' }) // Set card count loading to false
      const response = await xcpemSec.get(`${basUrl}`)
      const deviceStatistics = response.data
      dispatch({ type: 'GET_DEVICE_STATISTICS_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status

        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
      } else {
        notifyError(`Filter statistics: ${error.message}`)
        dispatch({ type: 'GET_DEVICE_STATISTICS_FAILURE', error: error.message })
      }
    }
  }
}

export const getDeviceStatisticsCount = () => {
  return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const basUrl = `/webapp_dashboard_all_devices`
    try {
      dispatch({ type: 'GET_DEVICE_STATISTICS_COUNT_REQUEST' }) // Set card count loading to false
      const response = await xcpemSec.get(`${basUrl}`)
      const deviceStatistics = response.data
      dispatch({ type: 'GET_DEVICE_STATISTICS_COUNT_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status

        // localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
      } else {
        notifyError(`Device Statistics count: ${error.message}`)
        dispatch({ type: 'GET_DEVICE_STATISTICS_FAILURE', error: error.message })
      }
    }
  }
}

export const getDeviceCount = () => {
  return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const basUrl = `/rpc/webapp_dashboard`
    try {
      dispatch({ type: 'GET_DEVICE_COUNT_REQUEST' }) // Set card count loading to false
      const response = await xcpemSec.get(`${basUrl}`)
      const hitronRes = []
      process.env.REACT_APP_REALM !== 'Xunison' && hitronRes.push(response.data)
      const deviceStatistics = process.env.REACT_APP_REALM === 'Xunison' ? response.data : hitronRes
      dispatch({ type: 'GET_DEVICE_COUNT_SUCCESS', payload: deviceStatistics })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status

        //  localStorage.clear()
        // localStorage.clear()
        // window.location.href = '/#/login'
      } else {
        notifyError(`Device count: ${error.message}`)
        dispatch({ type: 'GET_DEVICE_STATISTICS_FAILURE', error: error.message })
      }
    }
  }
}

export const getAccountGetWayInfo = (gatewayInfoEmail: any) => {
  return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const email = gatewayInfoEmail || localStorage.getItem('email')

    if (email) {
      const basUrl = `/webapp_dashboard_all_devices?email=ilike.*${encodeURIComponent(email)}*`

      try {
        dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_REQUEST' }) // Set card count loading to false
        const response = await xcpemSec.get(`${basUrl}`)
        const deviceStatistics = response.data
        dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS', payload: deviceStatistics })
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status

          //  localStorage.clear()
          // localStorage.clear()
          // window.location.href = '/#/login'
        } else {
          notifyError(`Get way info: ${error.message}`)
          dispatch({ type: 'GET_DEVICE_STATISTICS_FAILURE', error: error.message })
          dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS', payload: [] })
        }
      }
    } else {
      dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS', payload: [] })
    }
  }
}
export const getAccountGetWayInfoId = (gatewayInfoEmail: any) => {
  return async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const email = gatewayInfoEmail || localStorage.getItem('gwuid')

    if (email) {
      const basUrl = `/webapp_dashboard_all_devices?gw_uid=eq.${email}`

      try {
        dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_REQUEST' }) // Set card count loading to false
        const response = await xcpemSec.get(`${basUrl}`)
        const deviceStatistics = response.data
        dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS', payload: deviceStatistics })
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status

          //  localStorage.clear()
          // localStorage.clear()
          // window.location.href = '/#/login'
        } else {
          notifyError(`Get way info: ${error.message}`)
          dispatch({ type: 'GET_DEVICE_STATISTICS_FAILURE', error: error.message })
          dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS', payload: [] })
        }
      }
    } else {
      dispatch({ type: 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS', payload: [] })
    }
  }
}
