import React from 'react'

const PublicReport = () => {
  return (
    <iframe
      title='ods_data_view'
      width='100%'
      height='545'
      src='https://app.powerbi.com/view?r=eyJrIjoiNTk3NDRlZTEtZTIxNi00MjU1LTg1OGQtNTk5ZjFjYTM2ODE0IiwidCI6IjVhMTMyNjA5LTVhN2EtNDMwMy05ZjA5LTAwMDViNGM2MDlmNCIsImMiOjh9'
      frameBorder='0'
      allowFullScreen={true}
    ></iframe>
  )
}

export default PublicReport
