import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { Box } from '@mui/material'
import Loader from '../../../components/Loader'
import { getColorCode } from '../../../utils/helper'

const DCChart = ({ data, isFetching, selected, totalCount }: any) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  let maxVal: any = 0

  const chartCategories: string[] =
    data && data !== 401 && data.length !== 0
      ? data?.map((item: any) => {
          return item.date
        })
      : []

  const series1Data =
    data && data !== 401 && data.length !== 0
      ? selected === 4 || selected === 5
        ? data?.map((item: any) => {
            maxVal = maxVal < Math.ceil(item.success_count) ? Math.ceil(item.success_count) : maxVal
            return Math.ceil(item.success_count)
          })
        : data?.map((item: any) => {
            maxVal =
              maxVal < Math.ceil(item.total_count - item.response_fail_count)
                ? Math.ceil(item.total_count - item.response_fail_count)
                : maxVal
            return Math.ceil(item.total_count - item.response_fail_count)
          })
      : []
  const series2Data =
    data && data !== 401 && data.length !== 0
      ? data?.map((item: any) => {
          maxVal = maxVal < Math.ceil(item.response_fail_count) ? Math.ceil(item.response_fail_count) : maxVal
          return Math.ceil(item.response_fail_count)
        })
      : []

  const options = {
    chart: {
      type: 'column',
      backgroundColor: '#ffffff'
    },
    title: {
      text: undefined
    },
    xAxis: {
      categories: chartCategories,
      gridLineColor: 'transparent',
      lineWidth: 1,
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          // const momentValue = moment(this.value).local()

          const momentValue = moment.utc(this.value).tz(timeZone).format(`${process.env.REACT_APP_DATE_FORMAT}`)
          // return momentValue.format(` ${process.env.REACT_APP_DATE_FORMAT}`)
          return momentValue
        }
      }
    },
    yAxis: {
      title: {
        text: undefined
      },
      gridLineColor: 'transparent',
      lineWidth: 1,
      startOnTick: true,
      allowDecimals: false,
      min: 0,
      max: maxVal || 0
    },
    plotOptions: {
      bar: {
        groupPadding: 0,
        pointPadding: 0,
        dataLabels: {
          enabled: true
        },
        pointWidth: 100, // Adjust the width of each column
        maxPointWidth: 100 // Set the maximum width of each column
      },
      series: {
        pointWidth: 100, // This can be adjusted for overall width of the chart
        pointPadding: 0,
        pointMargin: 0
      }
    },
    series: [
      {
        type: 'column',
        name: 'Success',
        data: series1Data,
        color: getColorCode('chartPurpleGreen')
      },
      {
        type: 'column',
        name: 'Fail',
        data: series2Data,
        color: getColorCode('chartOrangeRed')
      }
    ],
    legend: {
      align: 'center',
      layout: 'horizontal',
      x: 0,
      y: 0,
      itemMarginTop: 10,
      itemMarginBottom: 10
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        //@ts-ignore
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          this.y +
          '</span>' +
          '</div>'
        )
      }
    }
  }

  return (
    <>
      {isFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <Box>
          {data && data.length !== 0 ? (
            <HighchartsReact highcharts={Highcharts} options={options} height={75} />
          ) : (
            <Box padding={'50px 0'} textAlign={'center'} color={getColorCode('redToBlue')}>
              Chart data not found
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
export default DCChart
