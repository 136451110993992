import React, { FC, ReactNode, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Cross2Icon } from '@radix-ui/react-icons'
import { ArrowDown2, ArrowUp2 } from 'iconsax-react'
import './styles.scss'
import { getColorCode } from '../../utils/helper'

interface NetworkMapPopoverProps {
  children: ReactNode
  title: string
}

const NetworkMapPopover: FC<NetworkMapPopoverProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover.Root open={isOpen}>
      <Popover.Trigger onClick={() => setIsOpen(!isOpen)}>
        <button
          type='button'
          onClick={() => setIsOpen(!isOpen)}
          className='network-map-filter popover-button p-2 bg-[#F0F0F3] rounded-[10px] font-sf-medium inline-flex items-center justify-between gap-x-4'
          aria-label={title}
        >
          <span>{title}</span>

          {isOpen ? <ArrowUp2 /> : <ArrowDown2 />}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className='PopoverContent shadow-2xl' sideOffset={5} onFocusOutside={() => setIsOpen(false)}>
          <div
            className='shadow-2xl p-3 py-4 network-map-filter bg-[#F0F0F3] rounded-[10px]'
            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
          >
            {children}
          </div>
          <Popover.Close
            className={`${process.env.REACT_APP_COLOR === 'hitron' ? 'PopoverCloseHitron' : process.env.REACT_APP_COLOR === 'customer'
            ? 'popoverCloseCustomer' : 'PopoverClose'}`}
            aria-label='Close'
            onClick={() => setIsOpen(false)}
            style={{
              color: getColorCode('redToBlue')
            }}
          >
            <Cross2Icon />
          </Popover.Close>
          <Popover.Arrow className='PopoverArrow' />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default NetworkMapPopover
